import firebase from "firebase/compat/app";
import { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  AccountType,
  UserInterface,
  UserRole,
} from "./../interfaces/User.interface";
import {
  usersCollectionName,
  accountsCollectionName,
  gasStationCollectionName,
  notificationsCollectionName,
  gasStationMembershipCollectionName,
  membershipCollectionName,
  sankmoneyFeesAccount,
  globalConfigCollectionsName,
  agentCreateUsersParams,
  superMerchantAndMerchantsForeingKeysCollectionsName,
  companiesMembershipCollectionName,
  companyCollectionName,
  superStationAndStationsForeingKeysCollectionsName,
} from "../config";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
export default function useFindUser() {
  const [user, setUser] = useState<UserInterface>();
  const [agencyAgent, setAgencyAgent] = useState<UserInterface>();
  const [gasStationInfo, setGasStationInfo] = useState<any>();
  const [userAccounts, setUserAccounts] = useState<any[]>([]);
  const [userNotifications, setUserNotifications] = useState<any[]>([]);
  const [agentCreateUserParams, setAgentCreateUserParams] = useState<any>();
  const [
    superMerchantAffiliatedMerchants,
    setSuperMerchantAffiliatedMerchants,
  ] = useState<any[]>([]);
  const [
    merchantAffiliatedToSuperMerchant,
    setMerchantAffiliatedToSuperMerchant,
  ] = useState(false);
  const [superStationAffiliatedStations, setSuperStationAffiliatedStations] =
    useState<any[]>([]);
  const [stationAffiliatedToSuperStation, setStationAffiliatedToSuperStation] =
    useState(false);
  const [company, setCompany] = useState<any>();
  const navigate = useNavigate();

  const agent_create_users_params_ref = db
    .collection(globalConfigCollectionsName)
    .doc(agentCreateUsersParams);

  const [isLoading, setIsLoading] = useState(true);

  const getAllUserWithAccounts = async (id: string) => {
    const userRef = db.collection(usersCollectionName).doc(id);
    const userSnapshot = await userRef.get();
    const notificationsSnap = await userRef
      .collection(notificationsCollectionName)
      .get();
    const notificationData = notificationsSnap.docs.map((notif) =>
      notif.data()
    );
    const usersData = userSnapshot.data();
    if (usersData) {
      if (!usersData?.active) {
        firebase.auth().signOut();
        toast.error(
          "Votre compte sur SankMoney a ete temporairement bloque. Merci de contacter le service client"
        );
        return navigate("/login-with-otp");
      }
      if (usersData?.userRole === UserRole.ONEAAGENT) {
        firebase.auth().signOut();
        toast.error(
          "Vous n'êtes pas authorisé à accédé à cette console (401)."
        );
        return navigate("/login-with-email");
      }
      setIsLoading(false);
      switch (usersData?.userRole) {
        case UserRole.AGENCYSTAFF:
          retriveAgencyData(id);
          setUser(usersData as UserInterface);
          break;
        case UserRole.GASSTATIONSTAFF:
          retriveGasStationData(id);
          setUser(usersData as UserInterface);
          setUserNotifications(notificationData);
          retriveStationAccountData(id);
          break;
        case UserRole.ADMIN:
          retriveAdminAccountData();
          setUser(usersData as UserInterface);
          setUserNotifications(notificationData);
          break;
        case UserRole.MARCHAND:
          retriveMerchantAccountData(userSnapshot, id);
          setUser(usersData as UserInterface);
          setUserNotifications(notificationData);
          break;
        case UserRole.SUPERMERCHANT:
          setUser(usersData as UserInterface);
          const superMerchantAccountSnapShot = await userSnapshot.ref
            .collection(accountsCollectionName)
            .get();
          const superMerchantAccountsData =
            superMerchantAccountSnapShot.docs.map((account) => account.data());
          setUserAccounts(superMerchantAccountsData);
          setUserNotifications(notificationData);
          retirveAffiliatedMerchand(id);
          break;
        case UserRole.SUPERSTATION:
          setUser(usersData as UserInterface);
          const superStationAccountSnapShot = await userSnapshot.ref
            .collection(accountsCollectionName)
            .get();
          const superStationAccountsData = superStationAccountSnapShot.docs.map(
            (account) => account.data()
          );
          setUserAccounts(superStationAccountsData);
          setUserNotifications(notificationData);
          retrieveveAffiliatedStation(id);
          break;
        case UserRole.COMPANYSTAFF:
          retriveCompanyData(id);
          setUser(usersData as UserInterface);
          setUserNotifications(notificationData);
          break;
        default:
          const userAccountSnapShot = await userSnapshot.ref
            .collection(accountsCollectionName)
            .get();
          const userAccountsData = userAccountSnapShot.docs.map((account) =>
            account.data()
          );
          setUserAccounts(userAccountsData);
          setUser(usersData as UserInterface);
          setUserNotifications(notificationData);
          break;
      }
    } else {
      setIsLoading(false);
    }

    const paramsSnap = await agent_create_users_params_ref.get();
    if (paramsSnap.exists) {
      setAgentCreateUserParams(paramsSnap?.data());
    }
  };

  const retriveAgencyData = async (id: string) => {
    const membershipRef = db.collection(membershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", id)
      .get();
    const userRef = db.collection(usersCollectionName);
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      const agencyAgentData = await userRef
        .where("userRole", "==", UserRole.AGENT)
        .where("agencyId", "==", membershipData?.agencyId)
        .get();
      setAgencyAgent(agencyAgentData.docs[0]?.data() as UserInterface);
      const notificationsSnap = await userRef
        .doc(agencyAgentData.docs[0]?.id)
        .collection(notificationsCollectionName)
        .get();
      const userAccountSnapShot = await userRef
        .doc(agencyAgentData.docs[0]?.id)
        .collection(accountsCollectionName)
        .get();
      setAgencyAgent(agencyAgentData.docs[0].data() as UserInterface);
      const userAccountsData = userAccountSnapShot.docs.map((account) =>
        account.data()
      );
      const notificationData = notificationsSnap.docs.map((notif) =>
        notif.data()
      );
      setUserNotifications(notificationData);
      setUserAccounts(userAccountsData);
    } else {
      setIsLoading(false);
    }
  };

  const retriveCompanyData = async (id: string) => {
    const membershipRef = db.collection(companiesMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", id)
      .get();
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      const companyRef = db
        .collection(companyCollectionName)
        .doc(membershipData?.companyId);
      const companySnap = await companyRef.get();
      const userAccountSnapShot = await companyRef
        .collection(accountsCollectionName)
        .get();
      const notificationsSnap = await companyRef
        .collection(notificationsCollectionName)
        .get();
      const userAccountsData = userAccountSnapShot.docs.map((account) =>
        account.data()
      );
      const notificationData = notificationsSnap.docs.map((notif) =>
        notif.data()
      );
      setUserNotifications(notificationData);
      setUserAccounts(userAccountsData);
      if (companySnap.exists) {
        setCompany(companySnap.data());
      }
    } else {
      setIsLoading(false);
    }
  };

  const retriveGasStationData = async (id: string) => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);

    const membershipSnaphot = await membershipRef
      .where("memberId", "==", id)
      .get();
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      const gasStationRef = db
        .collection(gasStationCollectionName)
        .doc(membershipData?.gasStationId);
      const gasStationSnap = await gasStationRef.get();
      const userAccountSnapShot = await gasStationRef
        .collection(accountsCollectionName)
        .get();
      const notificationsSnap = await gasStationRef
        .collection(notificationsCollectionName)
        .get();
      const userAccountsData = userAccountSnapShot.docs.map((account) =>
        account.data()
      );
      const notificationData = notificationsSnap.docs.map((notif) =>
        notif.data()
      );
      setUserNotifications(notificationData);
      setUserAccounts(userAccountsData);
      if (gasStationSnap.exists) {
        setGasStationInfo(gasStationSnap.data());
      }
    } else {
      setIsLoading(false);
    }
  };

  const retriveAdminAccountData = async () => {
    const sankAccountRef = db.collection(sankmoneyFeesAccount);
    const accountSnapshot = await sankAccountRef.get();
    if (!accountSnapshot.empty) {
      const data = accountSnapshot.docs.map((account) => account.data());
      setUserAccounts(data);
      // setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const retriveMerchantAccountData = async (
    userSnapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
    id: string
  ) => {
    const userAccountSnapShot = await userSnapshot.ref
      .collection(accountsCollectionName)
      .get();
    const membershipSnapshot = await db
      .collection(superMerchantAndMerchantsForeingKeysCollectionsName)
      .where("memberId", "==", id)
      .get();
    if (!userAccountSnapShot.empty) {
      const userAccountsData = userAccountSnapShot.docs.map((account) =>
        account.data()
      );
      setUserAccounts(userAccountsData);
      if (!membershipSnapshot.empty) {
        setMerchantAffiliatedToSuperMerchant(true);
      }
    } else {
      setIsLoading(false);
    }
  };

  const retirveAffiliatedMerchand = async (id: string) => {
    const userRef = db.collection(usersCollectionName);
    const membershipSnapshot = await db
      .collection(superMerchantAndMerchantsForeingKeysCollectionsName)
      .where("ownerId", "==", id)
      .get();
    if (!membershipSnapshot.empty) {
      const data: any[] = [];
      for (const membership of membershipSnapshot.docs) {
        const res = await userRef.doc(membership?.data()?.memberId).get();
        let memberData: any;
        if (res.exists) {
          const accountsSnap = await userRef
            .doc(res.id)
            .collection(accountsCollectionName)
            .get();
          const accounts = accountsSnap.docs
            .map((account) => account.data())
            .find((el) => el.accountType === AccountType.MAIN);
          memberData = {
            accounts,
            ...res.data(),
          };
        }
        data.push(memberData);
      }
      setSuperMerchantAffiliatedMerchants(data);
    } else {
      setIsLoading(false);
    }
  };

  const retriveStationAccountData = async (id: string) => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);

    const membershipSnaphot = await membershipRef
      .where("memberId", "==", id)
      .get();
    const membershipData = membershipSnaphot.docs[0].data();
    if (!membershipSnaphot.empty) {
      const gasStationRef = db
        .collection(gasStationCollectionName)
        .doc(membershipData?.gasStationId);
      const userAccountSnapShot = await gasStationRef
        .collection(accountsCollectionName)
        .get();
      const membershipSnapshot = await db
        .collection(superStationAndStationsForeingKeysCollectionsName)
        .where("memberId", "==", membershipData?.gasStationId)
        .get();
      if (!userAccountSnapShot.empty) {
        const userAccountsData = userAccountSnapShot.docs.map((account) =>
          account.data()
        );
        setUserAccounts(userAccountsData);
          if (!membershipSnapshot.empty) {
          setStationAffiliatedToSuperStation(true);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const retrieveveAffiliatedStation = async (id: string) => {
    const membershipRef = db.collection(gasStationCollectionName);
    const membershipSnapshot = await db
      .collection(superStationAndStationsForeingKeysCollectionsName)
      .where("ownerId", "==", id)
      .get();
    if (!membershipSnapshot.empty) {
      const data: any[] = [];
      for (const membership of membershipSnapshot.docs) {
        const res = await membershipRef.doc(membership?.data()?.memberId).get();
        let memberData: any;
        if (res.exists) {
          const accountsSnap = await membershipRef
            .doc(res.id)
            .collection(accountsCollectionName)
            .get();
          const accounts = accountsSnap.docs
            .map((account) => account.data())
            .find((el) => el.accountType === AccountType.FUEL);
          memberData = {
            accounts,
            ...res.data(),
          };
        }
        data.push(memberData);
      }
      setSuperStationAffiliatedStations(data);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (response) => {
      if (response) {
        getAllUserWithAccounts(response?.uid);
      } else {
        setUser(undefined);
      }
    });

    // Nettoyer l'abonnement lors du démontage
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    agencyAgent,
    userAccounts,
    userNotifications,
    gasStationInfo,
    agentCreateUserParams,
    superMerchantAffiliatedMerchants,
    company,
    superStationAffiliatedStations,
    stationAffiliatedToSuperStation,
    setUser,
    setAgencyAgent,
    setUserAccounts,
    setUserNotifications,
    isLoading,
    setCompany,
    setIsLoading,
    setGasStationInfo,
    setAgentCreateUserParams,
    setSuperMerchantAffiliatedMerchants,
    merchantAffiliatedToSuperMerchant,
    setMerchantAffiliatedToSuperMerchant,
    setSuperStationAffiliatedStations,
    setStationAffiliatedToSuperStation,
  };
}
