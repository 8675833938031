import {
  ArrowRightOnRectangleIcon,
  ArrowUpIcon,
  BoltIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import {
  AccountType,
  AgencyStaffRole,
  AttendantRole,
  UserInterface,
  UserRole,
  accountMap,
  userRoleMap,
} from "../../interfaces";
import { classNames } from "../../utils";
import {
  ArrowPathIcon,
  ArrowTrendingUpIcon,
  BanknotesIcon,
  BookOpenIcon,
  BookmarkIcon,
  BuildingOfficeIcon,
  CalculatorIcon,
  CheckCircleIcon,
  CodeBracketSquareIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  LinkIcon,
  QrCodeIcon,
  UsersIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/outline";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HasRoles from "../HasRoles";
import { UserContext } from "../../contexts";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DashboardReport } from "./DashboardReport";
import { DisplayPrice } from "../PriceDisplay";

const DealerOrAgentDashboardComponent = (props: {
  user?: UserInterface;
  setShowQrcodeGenerateModal: React.Dispatch<SetStateAction<boolean>>;
  transactions: any[];
  userAccountsData: any[];
  commissions?: number;
  currentUserStatus: boolean;
  refillsAwaitingValidation: any;
  setShowrefillsAwaitingValidationModal: React.Dispatch<
    SetStateAction<boolean>
  >;
  dealerAccountrefillsAwaitingValidation: any;
  setShowDealerAccountRefillsAwaitingValidationModal: React.Dispatch<
    SetStateAction<boolean>
  >;
  usersAffiliatedToAgent?: number;
  setShowAssignCardToUserModal: React.Dispatch<SetStateAction<boolean>>;
  retrieveSnblPrepaidBalance: () => void;
}) => {
  const {
    agencyAgent,
    gasStationInfo,
    merchantAffiliatedToSuperMerchant,
    stationAffiliatedToSuperStation,
  } = useContext(UserContext);
  const {
    user,
    setShowQrcodeGenerateModal,
    userAccountsData,
    commissions,
    currentUserStatus,
    refillsAwaitingValidation,
    setShowrefillsAwaitingValidationModal,
    dealerAccountrefillsAwaitingValidation,
    setShowDealerAccountRefillsAwaitingValidationModal,
    usersAffiliatedToAgent,
    setShowAssignCardToUserModal,
    retrieveSnblPrepaidBalance,
  } = props;

  const getTransferPageLink = () => {
    switch (user?.userRole) {
      case UserRole.ADMIN:
        return "/transfer/reload_agency";
      case UserRole.AGENCYSTAFF:
        return "/transfer/reload";
      case UserRole.DEALER:
        return "/transfer/reload";
      case UserRole.AGENT:
        return "/transfer/reload_user";
      case UserRole.USER:
        return "/transfer/transfer_to_user";
      default:
        return "/transfer/transfer_to_user";
    }
  };

  const [merchandaccount] = useState(
    userAccountsData &&
      userAccountsData.length > 0 &&
      userAccountsData.filter(
        (account) => account.accountType === AccountType.MAIN
      )
  );

  const navigate = useNavigate();

  const canDisplayLogs = ["ADMIN", "MANAGER", "CASHIER"];

  return (
    <>
      <div className="px-4 py-2 max-md:px-2 ">
        <div className="">
          <div className="">
            <div className="flex flex-wrap max-md:mx-4 max-md:space-y-2 max-md:flex-col">
              <div
                className={classNames(
                  user?.userRole === UserRole.SUPERVISOR ? "" : "hidden",
                  "py-2 max-md:flex"
                )}
              >
                {/* Profile */}
                <div className="items-center">
                  <div>
                    <div className="flex items-center">
                      <img
                        className="w-12 h-12 rounded-full"
                        src={
                          user?.profilImageUrl
                            ? user?.profilImageUrl
                            : `https://ui-avatars.com/api/?background=random&name=${
                                user?.displayName || "S"
                              }`
                        }
                        alt={user?.displayName}
                      />
                      <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                        Bienvenue
                        {user?.displayName ? `, ${user?.displayName}` : ""}
                      </h1>
                    </div>
                    <dl className="flex flex-col mt-6 sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                      <dt className="sr-only">Company</dt>
                      <dd className="flex items-center text-sm font-medium text-gray-500 capitalize sm:mr-6">
                        <BuildingOfficeIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {`${user?.city ? `${user?.city},` : ""} ${
                          user?.adresse ? `${user?.adresse},` : ""
                        } ${user?.phoneNumber ? `${user?.phoneNumber}` : ""}`}
                      </dd>
                      <dt className="sr-only">Account status</dt>
                      <HasRoles userRole={["USER"]}>
                        <dd
                          className={classNames(
                            user?.isCertify ? "text-green-500" : "text-red-500",
                            "flex items-center mt-3 text-sm font-medium capitalize sm:mr-6 sm:mt-0"
                          )}
                        >
                          <CheckCircleIcon
                            className={classNames(
                              user?.isCertify
                                ? "text-green-400"
                                : "text-red-400",
                              "mr-1.5 h-5 w-5 flex-shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {user?.isCertify
                            ? "Certifié"
                            : "En attente de certification"}
                        </dd>
                      </HasRoles>

                      <dd className="flex items-center mt-3 text-sm font-medium sm:mr-6 sm:mt-0">
                        <ArrowRightOnRectangleIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                          aria-hidden="true"
                        />
                        <div>
                          <span className="text-gray-500">
                            Connecté en tant que{" "}
                          </span>
                          <span className="text-blue-300">
                            {userRoleMap.get(
                              (user?.userRole === UserRole.AGENCYSTAFF
                                ? user?.userAgencySubRole
                                : user?.userRole === UserRole.GASSTATIONSTAFF
                                ? user?.attendantRole
                                : user?.userRole === UserRole.COMPANYSTAFF
                                ? user?.companyAttendantRole
                                : user?.userRole) as string
                            )}
                          </span>
                        </div>
                      </dd>
                      <HasRoles userRole={["AGENCYSTAFF", "AGENT"]}>
                        <>
                          <dd className="flex items-center mt-3 text-sm font-medium sm:mr-6 sm:mt-0">
                            <CodeBracketSquareIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-orange-400"
                              aria-hidden="true"
                            />
                            <div>
                              <span className="text-gray-500">
                                Votre code agent est{" "}
                              </span>
                              <span className="text-orange-300">
                                {agencyAgent
                                  ? agencyAgent?.agentCode
                                  : user?.agentCode}
                              </span>
                            </div>
                          </dd>
                        </>
                      </HasRoles>
                      <HasRoles userRole={["GASSTATIONSTAFF"]}>
                        <>
                          <dd className="flex items-center mt-3 text-sm font-medium sm:mr-6 sm:mt-0">
                            <CodeBracketSquareIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-orange-400"
                              aria-hidden="true"
                            />
                            <div>
                              <span className="text-gray-500">
                                Votre code agent est{" "}
                              </span>
                              <span className="text-orange-300">
                                {gasStationInfo?.gasStationCode}
                              </span>
                            </div>
                          </dd>
                        </>
                      </HasRoles>
                      <HasRoles userRole={["MERCHANT"]}>
                        <>
                          <dd className="flex items-center mt-3 text-sm font-medium sm:mr-6 sm:mt-0">
                            <CodeBracketSquareIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-orange-400"
                              aria-hidden="true"
                            />
                            <div>
                              <span className="text-gray-500">
                                Votre code marchant est{" "}
                              </span>
                              <span className="text-orange-300">
                                {user?.merchantCode}
                              </span>
                            </div>
                          </dd>
                        </>
                      </HasRoles>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="flex flex-wrap mt-2 mb-3 max-md:mx-4 max-md:flex-col max-md:space-y-4 ">
                  {user?.reviewer && (
                    <div
                      data-tooltip-id={`${
                        refillsAwaitingValidation?.exist
                          ? "reload_sank_money_account"
                          : undefined
                      }`}
                      className="flex my-auto space-x-3 sm:mb-2 md:ml-4"
                    >
                      <button
                        type="button"
                        className={classNames(
                          refillsAwaitingValidation?.exist
                            ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                            : "cursor-not-allowed text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500",
                          "inline-flex items-center px-4 py-2 text-sm md:mb-2 font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                        )}
                        onClick={() => {
                          setShowrefillsAwaitingValidationModal(true);
                        }}
                        disabled={
                          refillsAwaitingValidation?.exist ? false : true
                        }
                      >
                        <CheckIcon
                          className={classNames(
                            refillsAwaitingValidation?.exist
                              ? "text-green-500"
                              : "text-gray-500",
                            "self-center flex-shrink-0 w-5 h-5 mr-1"
                          )}
                          aria-hidden="true"
                        />
                        Valider une transaction
                        {refillsAwaitingValidation?.exist && (
                          <span className="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-red-800 bg-yellow-500 rounded-full"></span>
                        )}
                      </button>
                    </div>
                  )}
                  {user?.dealerReviewer && (
                    <div
                      data-tooltip-id={`${
                        dealerAccountrefillsAwaitingValidation?.exist
                          ? "reload_sank_money_dealer_account"
                          : undefined
                      }`}
                      className="flex my-auto space-x-3 sm:mb-2 md:ml-4"
                    >
                      <button
                        type="button"
                        className={classNames(
                          dealerAccountrefillsAwaitingValidation?.exist
                            ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                            : "cursor-not-allowed text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500",
                          "inline-flex items-center px-4 py-2 text-sm md:mb-2 font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                        )}
                        onClick={() => {
                          setShowDealerAccountRefillsAwaitingValidationModal(
                            true
                          );
                        }}
                        disabled={
                          dealerAccountrefillsAwaitingValidation?.exist
                            ? false
                            : true
                        }
                      >
                        <CheckIcon
                          className={classNames(
                            dealerAccountrefillsAwaitingValidation?.exist
                              ? "text-green-500"
                              : "text-gray-500",
                            "self-center flex-shrink-0 w-5 h-5 mr-1"
                          )}
                          aria-hidden="true"
                        />
                        Valider la recharge du compte dealer
                        {dealerAccountrefillsAwaitingValidation?.exist && (
                          <span className="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-red-800 bg-yellow-500 rounded-full"></span>
                        )}
                      </button>
                    </div>
                  )}
                  <HasRoles userRole={["ADMIN"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-green-700 bg-white border border-green-300 rounded-md shadow-sm md:mb-2 hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate("/reload_sank_money_account");
                        }}
                      >
                        <ArrowPathIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-green-500"
                          aria-hidden="true"
                        />
                        Les recharges du compte principal
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["ADMIN"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-md shadow-sm md:mb-2 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate("/reload_sank_money_dealer_account");
                        }}
                      >
                        <ArrowPathIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-blue-500"
                          aria-hidden="true"
                        />
                        Les recharges des comptes dealers
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["COMPANY", "COMPANYSTAFF"]}>
                    <>
                      <div className="flex my-auto space-x-3 md:ml-4">
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-yellow-700 bg-white border rounded-md shadow-sm md:mb-2 border-text-yellow-300 hover:bg-text-yellow-50 focus:outline-none focus:ring-2 focus:ring-text-yellow-500 focus:ring-offset-2"
                          disabled={currentUserStatus}
                          onClick={() => {
                            navigate("/companies/contacts/contact_lists");
                          }}
                        >
                          <BookmarkIcon
                            className="self-center flex-shrink-0 w-5 h-5 mr-1 text-yellow-500"
                            aria-hidden="true"
                          />
                          Mes contacts
                        </button>
                      </div>
                      <div className="flex my-auto space-x-3 md:ml-4">
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm md:mb-2 text-lime-700 border-text-lime-300 hover:bg-text-lime-50 focus:outline-none focus:ring-2 focus:ring-text-lime-500 focus:ring-offset-2"
                          disabled={currentUserStatus}
                          onClick={() => {
                            navigate("/companies/transactions-report");
                          }}
                        >
                          <DocumentChartBarIcon
                            className="self-center flex-shrink-0 w-5 h-5 mr-1 text-lime-500"
                            aria-hidden="true"
                          />
                          Rapports des transactions
                        </button>
                      </div>
                      <div className="flex my-auto space-x-3 md:ml-4">
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-700 bg-white border rounded-md shadow-sm md:mb-2 border-text-red-300 hover:bg-text-red-50 focus:outline-none focus:ring-2 focus:ring-text-red-500 focus:ring-offset-2"
                          disabled={currentUserStatus}
                          onClick={() => {
                            navigate(
                              "/companies/bulk-payment-transactions-report"
                            );
                          }}
                        >
                          <DocumentChartBarIcon
                            className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                            aria-hidden="true"
                          />
                          Rapports des paiements en masse
                        </button>
                      </div>
                      <div className="flex my-auto space-x-3 md:ml-4">
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border rounded-md shadow-sm md:mb-2 border-text-blue-300 hover:bg-text-blue-50 focus:outline-none focus:ring-2 focus:ring-text-blue-500 focus:ring-offset-2"
                          disabled={currentUserStatus}
                          onClick={() => {
                            navigate("/companies/bulk-paiement");
                          }}
                        >
                          <BanknotesIcon
                            className="self-center flex-shrink-0 w-5 h-5 mr-1 text-blue-500"
                            aria-hidden="true"
                          />
                          Paiement
                        </button>
                      </div>
                    </>
                  </HasRoles>
                  <HasRoles userRole={["AGENCYSTAFF"]}>
                    <>
                      {user?.userAgencySubRole === AgencyStaffRole.MANAGER && (
                        <div className="flex my-auto space-x-3 md:ml-4">
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm md:mb-2 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            disabled={currentUserStatus}
                            onClick={() => navigate("/agencies/audit_logs")}
                          >
                            <BookOpenIcon
                              className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                              aria-hidden="true"
                            />
                            Les audits logs des membres
                          </button>
                        </div>
                      )}
                    </>
                  </HasRoles>
                  <HasRoles userRole={["AGENCYSTAFF"]}>
                    <>
                      {user?.userAgencySubRole === AgencyStaffRole.CASHIER && (
                        <div className="flex my-auto space-x-3 md:ml-4">
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm md:mb-2 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            disabled={currentUserStatus}
                            onClick={() => navigate("/transfer/reload")}
                          >
                            <ArrowPathIcon
                              className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                              aria-hidden="true"
                            />
                            Recharger
                          </button>
                        </div>
                      )}
                    </>
                  </HasRoles>
                  <HasRoles userRole={["MERCHANT", "AGENT", "USER"]}>
                    <>
                      {((user?.userRole === UserRole.USER && user?.isSeller) ||
                        user?.userRole !== UserRole.USER) && (
                        <div className="flex space-x-3 md:ml-4">
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-purple-700 bg-white border border-purple-300 rounded-md shadow-sm md:mb-2 hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            disabled={currentUserStatus}
                            onClick={() => setShowAssignCardToUserModal(true)}
                          >
                            <CreditCardIcon
                              className="self-center flex-shrink-0 w-5 h-5 mr-1 text-purple-500"
                              aria-hidden="true"
                            />
                            Attribuer une carte
                          </button>
                        </div>
                      )}
                    </>
                  </HasRoles>
                  <>
                    {[
                      AgencyStaffRole.MANAGER,
                      AgencyStaffRole.CASHIER,
                    ].includes(user?.userAgencySubRole as AgencyStaffRole) && (
                      <HasRoles userRole={["AGENCYSTAFF"]}>
                        <div className="flex space-x-3 md:ml-4">
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-purple-700 bg-white border border-purple-300 rounded-md shadow-sm md:mb-2 hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            disabled={currentUserStatus}
                            onClick={() => setShowAssignCardToUserModal(true)}
                          >
                            <CreditCardIcon
                              className="self-center flex-shrink-0 w-5 h-5 mr-1 text-purple-500"
                              aria-hidden="true"
                            />
                            Attribuer une carte
                          </button>
                        </div>
                      </HasRoles>
                    )}
                  </>
                  <HasRoles userRole={["MERCHANT"]}>
                    <div className="flex space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-md shadow-sm md:mb-2 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => navigate("/payment_with_card")}
                      >
                        <CreditCardIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-blue-500"
                          aria-hidden="true"
                        />
                        Paiement carte
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["ADMIN", "AGENT", "DEALER", "USER"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm md:mb-2 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate(getTransferPageLink());
                        }}
                      >
                        <ArrowUpIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                          aria-hidden="true"
                        />
                        Transférer
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["AGENCYSTAFF"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm md:mb-2 text-cyan-700 border-cyan-300 hover:bg-cyan-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate("/transfer/reload_company");
                        }}
                      >
                        <ArrowPathIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-cyan-500"
                          aria-hidden="true"
                        />
                        Recharger une entreprise
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["AGENT", "DEALER", "USER"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-md shadow-sm md:mb-2 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate(
                            user?.userRole === UserRole.USER
                              ? "/withdraw_user"
                              : "/partenaires_withdraw"
                          );
                        }}
                      >
                        <BanknotesIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-blue-500"
                          aria-hidden="true"
                        />
                        {user?.userRole !== UserRole.USER
                          ? "Retrait à l'agence"
                          : "Retrait"}
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["AGENT"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-md shadow-sm md:mb-2 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate("/withdraw_agent");
                        }}
                      >
                        <BanknotesIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-blue-500"
                          aria-hidden="true"
                        />
                        Retrait chez un dealer
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["AGENT", "DEALER", "MERCHANT", "USER"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-green-700 bg-white border border-green-300 rounded-md shadow-sm md:mb-2 hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          setShowQrcodeGenerateModal(true);
                        }}
                      >
                        <QrCodeIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-green-500"
                          aria-hidden="true"
                        />
                        {"QR-Code"}
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["GASSTATIONSTAFF"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-orange-700 bg-white border border-orange-300 rounded-md shadow-sm md:mb-2 hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate("/gas-stations/order/new");
                        }}
                      >
                        {user?.userRole !== UserRole.GASSTATION && (
                          <BanknotesIcon
                            className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                            aria-hidden="true"
                          />
                        )}
                        Initier un paiement
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["AGENT", "DEALER", "AGENCYSTAFF"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-md shadow-sm md:mb-2 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate("/withdraw");
                        }}
                      >
                        <BanknotesIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-blue-500"
                          aria-hidden="true"
                        />
                        Retrait avec carte
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["USER"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-md shadow-sm md:mb-2 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate("/payment/merchant");
                        }}
                      >
                        <BanknotesIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-blue-500"
                          aria-hidden="true"
                        />
                        Paiement
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["GASSTATIONSTAFF"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-md shadow-sm md:mb-2 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate("/gas-station/payment_with_card");
                        }}
                      >
                        <BanknotesIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-blue-500"
                          aria-hidden="true"
                        />
                        Paiement par carte
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["USER"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-yellow-700 bg-white border border-yellow-300 rounded-md shadow-sm md:mb-2 hover:bg-yellow-50 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                        disabled={currentUserStatus}
                        onClick={() => {
                          navigate("/bulkPayment");
                        }}
                      >
                        <BanknotesIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-yellow-500"
                          aria-hidden="true"
                        />
                        Paiement en masse
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles
                    userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}
                  >
                    <>
                      {(canDisplayLogs.includes(user?.userRole as string) ||
                        canDisplayLogs.includes(
                          user?.userAgencySubRole as string
                        )) && (
                        <div className="flex my-auto space-x-3 md:ml-4">
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-yellow-700 bg-white border border-yellow-300 rounded-md shadow-sm md:mb-2 hover:bg-yellow-50 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                            disabled={currentUserStatus}
                            onClick={() => {
                              canDisplayLogs.includes(
                                user?.userAgencySubRole as string
                              )
                                ? navigate("/audits/selle_cards_histories")
                                : navigate("/audits/logs");
                            }}
                          >
                            <BookOpenIcon
                              className="self-center flex-shrink-0 w-5 h-5 mr-1 text-yellow-500"
                              aria-hidden="true"
                            />
                            Audit logs
                          </button>
                        </div>
                      )}
                    </>
                  </HasRoles>
                  <HasRoles
                    userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}
                  >
                    <>
                      {[
                        AgencyStaffRole.MANAGER,
                        UserRole.ADMIN,
                        UserRole.TECHNICALSUPPORT,
                      ].includes(
                        (agencyAgent
                          ? user?.userAgencySubRole
                          : user?.userRole) as UserRole | AgencyStaffRole
                      ) && (
                        <div className="flex my-auto space-x-3 md:ml-4">
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm md:mb-2 text-violet-700 border-violet-300 hover:bg-violet-50 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                            onClick={() => {
                              navigate("/transactions");
                            }}
                          >
                            <ViewfinderCircleIcon
                              className="self-center flex-shrink-0 w-5 h-5 mr-1 text-violet-500"
                              aria-hidden="true"
                            />
                            Trouver une transaction
                          </button>
                        </div>
                      )}
                    </>
                  </HasRoles>
                  <HasRoles
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                      "USER",
                      "SUPERVISOR",
                    ]}
                  >
                    <>
                      {(user?.userRole === UserRole.ADMIN ||
                        user?.userRole === UserRole.TECHNICALSUPPORT ||
                        user?.userAgencySubRole === AgencyStaffRole.MANAGER ||
                        (user?.userRole === UserRole.USER && user?.isSeller) ||
                        user?.userRole === UserRole.SUPERVISOR) && (
                        <div className="flex my-auto space-x-3 md:ml-4">
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm md:mb-2 text-cyan-700 border-cyan-300 hover:bg-cyan-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              navigate("/affiliations");
                            }}
                          >
                            <LinkIcon
                              className="self-center flex-shrink-0 w-5 h-5 mr-1 text-cyan-500"
                              aria-hidden="true"
                            />
                            Affiliations
                          </button>
                        </div>
                      )}
                    </>
                  </HasRoles>
                  <HasRoles userRole={["ADMIN"]}>
                    <div className="flex my-auto space-x-3 md:ml-4">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm md:mb-2 text-cyan-700 border-cyan-300 hover:bg-cyan-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        onClick={() => retrieveSnblPrepaidBalance()}
                      >
                        <BanknotesIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-cyan-500"
                          aria-hidden="true"
                        />
                        Consulter le solde SONABEL PREPAID
                      </button>
                    </div>
                  </HasRoles>
                  <HasRoles userRole={["AGENT", "AGENCYSTAFF"]}>
                    <>
                      {[AgencyStaffRole.CASHIER, UserRole.AGENT].includes(
                        (agencyAgent
                          ? user?.userAgencySubRole
                          : user?.userRole) as UserRole | AgencyStaffRole
                      ) && (
                        <div className="flex my-auto space-x-3 md:ml-4">
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm md:mb-2 text-lime-700 border-lime-300 hover:bg-lime-50 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                            onClick={() => {
                              navigate("/withdrawWithCode");
                            }}
                          >
                            <CalculatorIcon
                              className="self-center flex-shrink-0 w-5 h-5 mr-1 text-lime-500"
                              aria-hidden="true"
                            />
                            Retrait avec code
                          </button>
                        </div>
                      )}
                    </>
                  </HasRoles>
                  <HasRoles userRole={["MERCHANT"]}>
                    <>
                      {user &&
                        user?.userRole === UserRole.MARCHAND &&
                        !merchantAffiliatedToSuperMerchant && (
                          <div className="flex my-auto space-x-3 md:ml-4">
                            <button
                              type="button"
                              className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm md:mb-2 text-lime-700 border-lime-300 hover:bg-lime-50 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                              onClick={() => {
                                navigate("/withdraw_merchant");
                              }}
                            >
                              <BanknotesIcon
                                className="self-center flex-shrink-0 w-5 h-5 mr-1 text-lime-500"
                                aria-hidden="true"
                              />
                              Retrait à l'agence
                            </button>
                          </div>
                        )}
                    </>
                  </HasRoles>
                  <HasRoles userRole={["GASSTATIONSTAFF"]}>
                    <>
                      {user &&
                        user?.userRole === UserRole.GASSTATIONSTAFF &&
                        !stationAffiliatedToSuperStation && (
                          <div className="flex my-auto space-x-3 md:ml-4">
                            <button
                              type="button"
                              className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm md:mb-2 text-lime-700 border-lime-300 hover:bg-lime-50 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                              onClick={() => {
                                navigate("/withdraw_station");
                              }}
                            >
                              <BanknotesIcon
                                className="self-center flex-shrink-0 w-5 h-5 mr-1 text-lime-500"
                                aria-hidden="true"
                              />
                              Retrait à l'agence
                            </button>
                          </div>
                        )}
                    </>
                  </HasRoles>
                </div>
              </div>
            </div>
          </div>
        </div>
        <dl className="flex flex-wrap max-md:mx-4 max-md:space-y-2 max-md:flex-col">
          {(user && user?.userRole === UserRole.MARCHAND && merchandaccount
            ? merchandaccount
            : (user && user?.userRole === UserRole.AGENT) ||
              (user && user?.userRole === UserRole.DEALER)
            ? [
                userAccountsData.find(
                  (account) => account.accountType === AccountType.MAIN
                ),
              ]
            : userAccountsData && userAccountsData
          ).map((account, index) => (
            <DashboardReport
              key={index}
              className="px-5 mb-2 md:mx-2 max-md:w-full"
              valueClasses="text-xl  font-semibold text-gray-900"
              leftIcon={
                account.accountType === AccountType.MAIN ? (
                  <BanknotesIcon
                    className="w-6 h-6 text-white"
                    aria-hidden="true"
                  />
                ) : account.accountType === AccountType.FUEL ? (
                  <BoltIcon className="w-6 h-6 text-white" aria-hidden="true" />
                ) : (
                  <BanknotesIcon
                    className="w-6 h-6 text-white"
                    aria-hidden="true"
                  />
                )
              }
              title={accountMap.get(account.accountType) || ""}
              value={<DisplayPrice price={account.balance ?? 0} />}
            />
          ))}

          <HasRoles
            userRole={["AGENT", "DEALER", "MERCHANT", "GASSTATIONSTAFF"]}
          >
            <DashboardReport
              className="px-5 md:mx-2 max-md:w-full"
              valueClasses="text-2xl font-semibold text-gray-900"
              leftIcon={
                <ArrowTrendingUpIcon
                  className="w-6 h-6 text-white"
                  aria-hidden="true"
                />
              }
              title="Commissions non reversée"
              value={<DisplayPrice price={commissions ?? 0} />}
            />
          </HasRoles>
          <HasRoles userRole={["AGENT"]}>
            <DashboardReport
              className="px-5 md:mx-2 max-md:w-full"
              valueClasses="text-2xl  font-semibold text-gray-900"
              leftIcon={
                <UsersIcon className="w-6 h-6 text-white" aria-hidden="true" />
              }
              title="Utilisateurs crées"
              value={
                <p className="flex items-baseline ml-2 text-sm font-semibold">
                  {usersAffiliatedToAgent ?? 0}
                </p>
              }
            />
          </HasRoles>
        </dl>
      </div>
      <ReactTooltip
        id="reload_sank_money_account"
        place="bottom"
        variant="warning"
        content="Vous avez une validation en attente"
      />
      <ReactTooltip
        id="reload_sank_money_dealer_account"
        place="bottom"
        variant="warning"
        content="Vous avez une validation en attente"
      />
    </>
  );
};

export default DealerOrAgentDashboardComponent;
