/* eslint-disable jsx-a11y/anchor-is-valid */
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  PhoneNumberDefaultLength,
  blockedTransactionCollectionName,
  companyCollectionsName,
  gasStationCollectionName,
  pendingTransactionsCollectionsName,
  transactionCollectionName,
  usersCollectionName,
} from "../../config";
import toast from "react-hot-toast";
import { db, functions } from "../../firebase";
import { useCallback, useContext, useState } from "react";
import { Country } from "country-state-city";
import {
  CheckCircleIcon,
  FunnelIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import {
  classNames,
  getDateTimeWithTimestamp,
  getTimestamp,
} from "../../utils";
import {
  TransactionStatus,
  TransactionType,
  UserRole,
  transactionMap,
  userRoleMap,
} from "../../interfaces";
import { Timestamp } from "firebase-admin/firestore";
import { useConfirm } from "../../hooks/useConfirm";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts";
import Modal from "../../components/Modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, FormInput } from "../../components/FormComponents";
import NoContent from "../../components/TableNoContentPage/NoContent";
import TableRowEditMenu from "../../components/TableRowEditMenu/TableRowEditMenu";
import { Tooltip as ReactTooltip } from "react-tooltip";
import HasRoles from "../../components/HasRoles";

const tableHeader = [
  "N°",
  "Date de création",
  "ID",
  "Nom du destinataire",
  "Téléphone du destinataire",
  "Montant",
  "Type de transaction",
  "Frais",
  "Statut",
  "",
  "Actions",
];
const pendingTransactionTableHeader = [
  "N°",
  "Date de création",
  "ID",
  "Téléphone du destinataire",
  "Montant",
  "Téléphone de l'expéditeur",
  "Actions",
];

export const TransationPage = () => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [numberOftransaction, setNumberOftransaction] = useState<number>(10);
  const [userID, setUserID] = useState("");
  const { user, agencyAgent } = useContext(UserContext);
  const [historyTransactionType, setHistoryTransactionType] = useState<
    TransactionType[] | any
  >([""]);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const { isConfirmModal } = useConfirm();
  const navigate = useNavigate();
  const [curentTransaction, setCurentTransaction] = useState<any>();
  const [showDeadlineModal, setShowDeadlineModal] = useState(false);
  const [isSubminted, setIsSubminted] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);
  const [transId, setTransId] = useState("");
  const [withdrawCode, setWithdrawCode] = useState("");
  const [
    cancelledPendingTransactionCheckState,
    setCancelledPendingTransactionCheckState,
  ] = useState(false);
  const [pendingTransactions, setPendingTransactions] = useState<any[]>([]);
  const [selectedVal, setSelectedVal] = useState<UserRole>(UserRole.USER);
  const [gasStationOrMarchandCode, setGasStationOrMarchantCode] = useState("");
  const [checkedAllTransactionTypeValue, setCheckedAllTransactionTypeValue] =
    useState<string[]>([]);

  const sankMoneyAdminCanceledTransaction = functions.httpsCallable(
    "sankMoneyAdminCanceledTransactionFn"
  );
  const sankMoneyAdminBlockedTransactionFn = functions.httpsCallable(
    "sankMoneyAdminBlockedTransactionFn"
  );
  const sankMoneyAdminBlockedPaymentTransactionFn = functions.httpsCallable(
    "sankMoneyAdminBlockedPaymentTransactionFn"
  );
  const sankMoneyAdminReturnTransactionFn = functions.httpsCallable(
    "sankMoneyAdminReturnTransactionFn"
  );
  const sankMoneyAdminCanceledPendingTransactionFn = functions.httpsCallable(
    "sankMoneyAdminCanceledPendingTransactionFn"
  );

  const canceledTransaction = async (data: any, transactionId: string) => {
    setShowDeadlineModal(false);
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir annuler la transaction ?",
      "Oui annuler",
      true
    );

    if (isConfirm) {
      setIsSubminted(true);
      const toastId = toast.loading("chargement...");
      sankMoneyAdminCanceledTransaction({
        senderId: data.senderID,
        receiverId: data.receiverID,
        transactionId: transactionId,
        amount: data.amount,
        transactionType: data.transactionType,
        accountType: data.accountType,
        userRole: selectedVal,
      })
        .then(() => {
          setIsSubminted(false);
          const filteredData =
            transactions?.length > 1
              ? transactions.filter((el) => el.id !== data.id)
              : [];
          setTransactions(filteredData);
          navigate(`/transactions`);
          toast.success("La transaction a été annulée avec success.", {
            id: toastId,
          });
        })
        .catch((e) => {
          toast.error(e.message, {
            id: toastId,
          });
        });
    }
  };
  const canceledPendingTransaction = async (
    data: any,
    transactionId: string
  ) => {
    setShowDeadlineModal(false);
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir annuler la transaction ?",
      "Oui annuler",
      true
    );

    if (isConfirm) {
      setIsSubminted(true);
      const toastId = toast.loading("chargement...");
      sankMoneyAdminCanceledPendingTransactionFn({
        transactionId: transactionId,
      })
        .then(() => {
          setIsSubminted(false);
          const filteredData =
            pendingTransactions?.length > 1
              ? pendingTransactions.filter((el) => el.id !== data.id)
              : [];
          setPendingTransactions(filteredData);
          toast.success("La transaction a été annuler avec success.", {
            id: toastId,
          });
        })
        .catch((e) => {
          toast.error(e.message, {
            id: toastId,
          });
        });
    }
  };

  const blockedTransaction = async (data: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir bloquer la transaction ?",
      "Oui bloquer",
      true
    );

    if (isConfirm) {
      setShowDeadlineModal(false);
      setIsSubminted(true);

      if (
        curentTransaction.transaction.transactionType ===
        TransactionType.PAYMENT
      ) {
        const toastId = toast.loading("chargement...");
        sankMoneyAdminBlockedPaymentTransactionFn({
          senderId: curentTransaction.transaction.senderID,
          receiverId: curentTransaction.transaction.receiverID,
          transactionId: curentTransaction?.id,
          amount: curentTransaction.transaction.amount,
          transactionType: curentTransaction.transaction.transactionType,
          accountType: curentTransaction.transaction.accountType,
          description: `motif: ${data.description}, delai: ${data.deadline}`,
          userRole: selectedVal,
        })
          .then(() => {
            setIsSubminted(false);
            setShowDeadlineModal(false);
            const filteredData =
              transactions?.length > 1
                ? transactions.filter((el) => el.id !== curentTransaction?.id)
                : [];
            setTransactions(filteredData);
            navigate(`/transactions`);
            toast.success("La transaction a été bloquée avec success.", {
              id: toastId,
            });
          })
          .catch((e) => {
            toast.error(e.message, {
              id: toastId,
            });
          });
      } else {
        const toastId = toast.loading("chargement...");
        sankMoneyAdminBlockedTransactionFn({
          senderId: curentTransaction.transaction.senderID,
          receiverId: curentTransaction.transaction.receiverID,
          transactionId: curentTransaction?.id,
          amount: curentTransaction.transaction.amount,
          transactionType: curentTransaction.transaction.transactionType,
          accountType: curentTransaction.transaction.accountType,
          description: `motif: ${data.description}, delai: ${data.deadline}`,
          userRole: selectedVal,
        })
          .then(() => {
            setIsSubminted(false);
            setShowDeadlineModal(false);
            const filteredData =
              transactions?.length > 1
                ? transactions.filter((el) => el.id !== curentTransaction?.id)
                : [];
            setTransactions(filteredData);
            navigate(`/transactions`);
            toast.success("La transaction a été bloquée avec success.", {
              id: toastId,
            });
          })
          .catch((e) => {
            toast.error(e.message, {
              id: toastId,
            });
          });
      }
    }
  };
  const returnTransaction = async (data: any, transactionId: string) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir restituer la transaction ?",
      "Oui restituer",
      true
    );

    if (isConfirm) {
      setIsSubminted(true);
      const toastId = toast.loading("chargement...");
      sankMoneyAdminReturnTransactionFn({
        receiverId: data.receiverID,
        transactionId: transactionId,
        amount: data.amount,
      })
        .then(() => {
          setIsSubminted(false);
          const filteredData =
            transactions?.length > 1
              ? transactions.filter((el) => el.id !== data.id)
              : [];
          setTransactions(filteredData);
          navigate(`/transactions`);
          toast.success("La transaction a été restituée avec success.", {
            id: toastId,
          });
        })
        .catch((e) => {
          toast.error(e.message, {
            id: toastId,
          });
        });
    }
  };

  const handleChangeStartDateValue = (value: any) => {
    const startTime = getDateTimeWithTimestamp(value.target.value);
    setStartTime(startTime);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    const endTime = getDateTimeWithTimestamp(value.target.value);
    setEndTime(endTime);
  };
  const handleChangeInputRadio = (value: any) => {
    setNumberOftransaction(value);
  };
  const handleCheckedAll = (value: any, checked: boolean) => {
    const allTransactions: string[] = [];
    Object.keys(TransactionType).forEach((transaction) =>
      allTransactions.push(transaction)
    );
    checked
      ? setCheckedAllTransactionTypeValue(allTransactions)
      : setCheckedAllTransactionTypeValue([]);
  };
  const handleChangeInputCheick = (value: any, checked: boolean) => {
    if (checked) {
      const isInclude = historyTransactionType.includes(value);
      if (!isInclude) {
        setHistoryTransactionType([...historyTransactionType, value]);
      }
    } else {
      setHistoryTransactionType(
        historyTransactionType.filter((type: any) => type !== value)
      );
    }
  };

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  const getCollectionName = useCallback(() => {
    if (user && user?.userRole === UserRole.ADMIN) {
      return checkedValue
        ? blockedTransactionCollectionName
        : transactionCollectionName;
    } else {
      return transactionCollectionName;
    }
  }, [checkedValue, user]);

  const filter = useCallback(async () => {
    if (
      !isValidPhoneNumber(userPhoneNumber) &&
      selectedVal !== UserRole.MARCHAND &&
      selectedVal !== UserRole.GASSTATION
    ) {
      return toast.error("Veuillez renseigner un numéro de téléphone");
    }
    const users =
      selectedVal === UserRole.MARCHAND || selectedVal === UserRole.GASSTATION
        ? await db
            .collection(retrieveSenderSnap(selectedVal))
            .where(
              `${
                selectedVal === UserRole.MARCHAND
                  ? "merchantCode"
                  : "gasStationCode"
              }`,
              "==",
              gasStationOrMarchandCode
            )
            .get()
        : await db
            .collection(retrieveSenderSnap(selectedVal))
            .where("phoneNumber", "==", userPhoneNumber)
            .get();
    if (users.empty) {
      toast.error("Le numéro n'existe pas");
      setTransactions([]);
    } else {
      setUserID(users.docs[0].id);
      const collectionName = getCollectionName();
      if (transId.length > 0) {
        const res = await db
          .collection(collectionName)
          .orderBy("createdAt", "desc")
          .where("participantsIds", "array-contains", users.docs[0].id)
          .get();
        res.docs.find((trans) => {
          let data: any;
          if (trans.id === transId) {
            data = trans;
            if (
              trans.data()?.participantsIds.includes(users.docs[0].id) &&
              trans.data()?.isComplete === true
            ) {
              setTransactions([{ id: trans.id, data: trans.data() }]);
            } else if (
              trans.data()?.participantsIds.includes(users.docs[0].id) &&
              trans.data()?.status === TransactionStatus.BLOCKED &&
              user?.userRole === UserRole.ADMIN &&
              checkedValue
            ) {
              setTransactions([{ id: trans.id, data: trans.data() }]);
            }
          }
          return data;
        });
      } else {
        if (startTime > endTime) {
          toast.error(
            "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
          );
        } else {
          const transRef =
            checkedAllTransactionTypeValue.length > 0
              ? db
                  .collection(collectionName)
                  .orderBy("createdAt", "desc")
                  .where("participantsIds", "array-contains", users.docs[0].id)
                  .where("createdAt", ">=", startTime)
                  .where("createdAt", "<=", endTime)
              : db
                  .collection(collectionName)
                  .orderBy("createdAt", "desc")
                  .where("participantsIds", "array-contains", users.docs[0].id)
                  .where("createdAt", ">=", startTime)
                  .where("createdAt", "<=", endTime)
                  .where("transactionType", "in", historyTransactionType);
          await transRef
            .limit(numberOftransaction as number)
            .get()
            .then((response) => {
              const data: any[] = [];
              response.docs.forEach((element) => {
                const el = element.data();
                if (
                  el?.participantsIds.includes(users.docs[0].id) &&
                  el?.isComplete === true
                ) {
                  data.push({ id: element.id, data: element.data() });
                } else if (
                  el?.participantsIds.includes(users.docs[0].id) &&
                  el?.status === TransactionStatus.BLOCKED &&
                  user?.userRole === UserRole.ADMIN &&
                  checkedValue
                ) {
                  data.push({ id: element.id, data: element.data() });
                }
              });
              setTransactions(data);
            });
        }
      }
    }
  }, [
    checkedAllTransactionTypeValue.length,
    checkedValue,
    endTime,
    gasStationOrMarchandCode,
    getCollectionName,
    historyTransactionType,
    numberOftransaction,
    selectedVal,
    startTime,
    transId,
    user?.userRole,
    userPhoneNumber,
  ]);

  const retrieveSenderSnap = (userRole: string) => {
    switch (userRole) {
      case UserRole.COMPANY:
        return companyCollectionsName;
      case UserRole.GASSTATION:
        return gasStationCollectionName;
      default:
        return usersCollectionName;
    }
  };

  const filterPendingTransaction = async () => {
    if (withdrawCode.length > 0) {
      const snap = await db
        .collection(pendingTransactionsCollectionsName)
        .orderBy("createdAt", "desc")
        .where("withdrawCode", "==", withdrawCode)
        .limit(numberOftransaction as number)
        .get();
      if (!snap.empty) {
        const data: any[] = [];
        snap.docs.forEach((element) => {
          data.push({ id: element.id, data: element.data() });
        });
        setPendingTransactions(data);
      } else toast.error("Pas de contenu disponible.");
    } else {
      if (!userPhoneNumber) {
        return toast.error("Veuillez renseigner un numéro de téléphone");
      }
      if (startTime > endTime) {
        toast.error(
          "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
        );
      } else {
        const snap = await db
          .collection(pendingTransactionsCollectionsName)
          .orderBy("createdAt", "desc")
          .where("senderPhoneNumber", "==", userPhoneNumber)
          .where("createdAt", ">=", startTime)
          .where("createdAt", "<=", endTime)
          .limit(numberOftransaction as number)
          .get();

        if (!snap.empty) {
          const data: any[] = [];
          snap.docs.forEach((element) => {
            data.push({ id: element.id, data: element.data() });
          });
          setPendingTransactions(data);
        } else toast.error("Pas de contenu disponible.");
      }
    }
  };

  const getFilterdByPhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber !== undefined) {
      if (isValidPhoneNumber(phoneNumber)) {
        setUserPhoneNumber(phoneNumber);
      }
    } else {
      setTransactions([]);
      setPendingTransactions([]);
    }
  };

  const handleChangeMerchantCodeSearch = async (code: string) => {
    if (code !== undefined) {
      setGasStationOrMarchantCode(code);
    }
  };

  return (
    <>
      <div className="pt-5 bg-white">
        <div className="px-6 max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
              <div>
                <div className="grid items-center justify-between grid-flow-col auto-cols-max">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    Filtre
                  </h2>
                  <div>
                    <HasRoles userRole={["ADMIN", "TECHNICALSUPPORT"]}>
                      <div className="inline-flex items-center justify-center p-1 space-y-1 bg-white rounded-md ring-1 ring-black ring-opacity-5">
                        <select
                          id="countries"
                          className="block w-auto px-4 py-2 text-sm text-gray-900 border-none rounded-lg "
                          onChange={(e) =>
                            setSelectedVal(e.target.value as any)
                          }
                        >
                          {[
                            UserRole.USER,
                            UserRole.AGENT,
                            UserRole.DEALER,
                            UserRole.MARCHAND,
                            UserRole.COMPANY,
                            UserRole.GASSTATION,
                          ].map((value, index) => (
                            <option
                              key={index}
                              selected={value === UserRole.AGENCYSTAFF && true}
                              value={value}
                            >
                              {userRoleMap.get(value)}
                            </option>
                          ))}
                        </select>
                      </div>
                    </HasRoles>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => {
                        cancelledPendingTransactionCheckState
                          ? filterPendingTransaction()
                          : filter();
                      }}
                    >
                      <FunnelIcon
                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                        aria-hidden="true"
                      />
                      Filtrer
                    </button>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="mt-4 leading-7 text-gray-600">
                  {(selectedVal && selectedVal === UserRole.GASSTATION) ||
                  selectedVal === UserRole.MARCHAND ? (
                    <div className="mr-1">
                      <input
                        type="text"
                        id="merchantCode"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder={`Entrer le code ${
                          selectedVal === UserRole.MARCHAND
                            ? "marchand"
                            : "de la station"
                        }`}
                        onChange={(e) =>
                          handleChangeMerchantCodeSearch(e.target.value)
                        }
                      />
                    </div>
                  ) : (
                    <PhoneInput
                      international={false}
                      placeholder="Entrez un numéro de téléphone"
                      className="block w-full px-3 py-2 placeholder-gray-400 shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                      defaultCountry="BF"
                      onChange={getFilterdByPhoneNumber}
                    />
                  )}
                </div>
                <HasRoles userRole={["ADMIN", "TECHNICALSUPPORT"]}>
                  <>
                    <hr className="my-4" />
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-2">
                      <div className="pb-4">
                        <div className="space-y-2">
                          <label className="flex items-center justify-between cursor-pointer">
                            <span className="text-sm text-gray-600">
                              Annuler une transaction en attente
                            </span>
                            <div>
                              <input
                                type="checkbox"
                                value=""
                                onChange={(e) =>
                                  setCancelledPendingTransactionCheckState(
                                    e.target.checked
                                  )
                                }
                                className="sr-only peer"
                                checked={cancelledPendingTransactionCheckState}
                              />
                              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    {cancelledPendingTransactionCheckState && (
                      <>
                        <hr className="my-4" />
                        <div className="mx-auto max-w-7xl sm:px-6 lg:px-2">
                          <div className="pb-4">
                            <div className="space-y-2">
                              <div className="inline-grid items-center">
                                <label
                                  htmlFor="inputText"
                                  className="flex justify-between mb-3 text-sm text-gray-600"
                                >
                                  Par code de retrait (optionnelle){" "}
                                  <QuestionMarkCircleIcon
                                    data-tooltip-id="retrive_pending_trans_by_code"
                                    className="self-center flex-shrink-0 w-5 h-5 ml-2 text-red-400"
                                  />
                                </label>
                                <input
                                  id="inputText"
                                  name="withdrawCode"
                                  placeholder="123456"
                                  type="text"
                                  onChange={(e) =>
                                    setWithdrawCode(e.target.value)
                                  }
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </HasRoles>
                <hr className="my-4" />
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-2">
                  <div className="pb-4">
                    <div className="space-y-2">
                      <div className="inline-grid items-center">
                        <label
                          htmlFor="inputText"
                          className="flex justify-between mb-3 text-sm text-gray-600"
                        >
                          ID de la transaction (optionnelle){" "}
                          <QuestionMarkCircleIcon
                            data-tooltip-id="retrive_trans_by_id"
                            className="self-center flex-shrink-0 w-5 h-5 ml-2 text-red-400"
                          />
                        </label>
                        <input
                          id="inputText"
                          name="transId"
                          placeholder="ID301123.1742.105E0"
                          type="text"
                          disabled={cancelledPendingTransactionCheckState}
                          onChange={(e) => setTransId(e.target.value)}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <HasRoles userRole={["ADMIN", "TECHNICALSUPPORT"]}>
                  <>
                    <hr className="my-4" />
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-2">
                      <div className="pb-4">
                        <div className="space-y-2">
                          <div className="flex items-center">
                            <input
                              id="checkbox"
                              name="size"
                              type="checkbox"
                              checked={checkedValue}
                              onChange={(e) => {
                                setCheckedValue(e.target.checked);
                              }}
                              disabled={cancelledPendingTransactionCheckState}
                              className="w-4 h-4 text-red-600 border-gray-300 rounded cursor-pointer focus:ring-red-500"
                            />
                            <label
                              htmlFor="checkbox"
                              className="ml-3 text-sm text-gray-600"
                            >
                              Transactions en attente d'être traiter
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </HasRoles>
                <hr className="my-4" />
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-2">
                  <div className="flex items-baseline justify-between pt-4 pb-6">
                    <p className="text-sm font-bold tracking-tight text-gray-500">
                      Nombre total
                    </p>
                  </div>
                  <div className="pb-4">
                    <div className="space-y-2">
                      <div
                        onClick={() => handleChangeInputRadio(10)}
                        className="flex items-center"
                      >
                        <input
                          id="filter-size-0"
                          readOnly
                          name="size"
                          value={10}
                          type="radio"
                          checked={numberOftransaction === 10}
                          className="w-4 h-4 text-red-600 border-gray-300 rounded cursor-pointer focus:ring-red-500"
                        />
                        <label
                          htmlFor="filter-size-0"
                          className="ml-3 text-sm text-gray-600"
                        >
                          10
                        </label>
                      </div>
                      <div
                        onClick={() => handleChangeInputRadio(20)}
                        className="flex items-center"
                      >
                        <input
                          id="filter-size-1"
                          readOnly
                          name="size"
                          value={20}
                          type="radio"
                          checked={numberOftransaction === 20}
                          className="w-4 h-4 text-red-600 border-gray-300 rounded cursor-pointer focus:ring-red-500"
                        />
                        <label
                          htmlFor="filter-size-1"
                          className="ml-3 text-sm text-gray-600"
                        >
                          20
                        </label>
                      </div>
                      <div
                        onClick={() => handleChangeInputRadio(50)}
                        className="flex items-center"
                      >
                        <input
                          id="filter-size-2"
                          readOnly
                          name="size"
                          value={50}
                          type="radio"
                          checked={numberOftransaction === 50}
                          className="w-4 h-4 text-red-600 border-gray-300 rounded cursor-pointer focus:ring-red-500"
                        />
                        <label
                          htmlFor="filter-size-2"
                          className="ml-3 text-sm text-gray-600"
                        >
                          50
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="justify-between mt-2 sm:flex-wrap lg:flex">
                  <div className="px-1 bg-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
                    <div className="mr-1">
                      <label
                        htmlFor="dateDebut"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Date début
                      </label>
                      <input
                        type="datetime-local"
                        id="dateDebut"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        defaultValue={`${moment(Date.now()).format(
                          "YYYY-MM-DD"
                        )}`}
                        onChange={handleChangeStartDateValue}
                      />
                    </div>
                    <div className="mr-1">
                      <label
                        htmlFor="dateFin"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Date fin
                      </label>
                      <input
                        type="datetime-local"
                        id="dateFin"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        defaultValue={`${moment(Date.now()).format(
                          "YYYY-MM-DD"
                        )}`}
                        onChange={handleChangeEndDtaeValue}
                      />
                    </div>
                  </div>
                </div>

                <hr className="my-4" />
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-2">
                  <div className="flex items-baseline justify-between pt-4 pb-6">
                    <p className="text-sm font-bold tracking-tight text-gray-500">
                      Transaction type
                    </p>
                  </div>

                  <div className="pb-4">
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <input
                          id="filter-size-0"
                          name="size_0"
                          value={checkedAllTransactionTypeValue}
                          type="checkbox"
                          disabled={cancelledPendingTransactionCheckState}
                          onChange={(e) =>
                            handleCheckedAll(e.target.value, e.target.checked)
                          }
                          className="w-4 h-4 text-red-600 border-gray-300 rounded focus:ring-red-500"
                        />
                        <label
                          htmlFor="filter-size-0"
                          className="ml-3 text-sm text-gray-600"
                        >
                          Tout
                        </label>
                      </div>
                      {Object.keys(TransactionType).map(
                        (transactionType, index) => (
                          <div key={index} className="flex items-center">
                            <input
                              id="filter-size-0"
                              name={`size_${index + 1}`}
                              value={transactionType}
                              type="checkbox"
                              disabled={cancelledPendingTransactionCheckState}
                              onChange={(e) =>
                                handleChangeInputCheick(
                                  e.target.value,
                                  e.target.checked
                                )
                              }
                              className="w-4 h-4 text-red-600 border-gray-300 rounded focus:ring-red-500"
                            />
                            <label
                              htmlFor="filter-size-0"
                              className="ml-3 text-sm text-gray-600"
                            >
                              {transactionMap.get(transactionType)}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
              </div>
              <div className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8 scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                <div className="flex flex-col mt-8">
                  <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                      <div className="shadow-sm">
                        {!cancelledPendingTransactionCheckState ? (
                          <table
                            className="min-w-full border-separate"
                            style={{ borderSpacing: 0 }}
                          >
                            <thead className="bg-gray-50">
                              <tr>
                                {tableHeader.map((th, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                  >
                                    {th}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {transactions && transactions.length > 0 ? (
                                transactions.map(
                                  (
                                    transaction: any,
                                    transactionIdx: number
                                  ) => (
                                    <tr key={transactionIdx}>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {(transactionIdx as number) + 1}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {(
                                          transaction.data
                                            .createdAt as Timestamp
                                        )
                                          .toDate()
                                          .toLocaleString()}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {transaction.id}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {userID &&
                                        userID === transaction.data.senderID
                                          ? transaction.data.receiverDisplayName
                                            ? transaction.data
                                                .receiverDisplayName
                                            : "-"
                                          : transaction.data.senderDisplayName
                                          ? transaction.data.senderDisplayName
                                          : "-"}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {(userID && userID) ===
                                        transaction.data.senderID
                                          ? (transaction.data
                                              .receiverPhoneNumber as string)
                                          : (transaction.data
                                              .senderPhoneNumber as string)}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {new Intl.NumberFormat().format(
                                          transaction.data.amount
                                        )}{" "}
                                        Fcfa
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {transactionMap.get(
                                          transaction.data.transactionType
                                        )}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {transaction.data.fees as string} Fcfa
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-red-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-red-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {transaction.data.status as string}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {transaction.data.isComplete ? (
                                          <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                        ) : (
                                          <XCircleIcon className="w-6 h-6 text-red-500" />
                                        )}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                                        )}
                                      >
                                        <TableRowEditMenu
                                          rowCancelledTransHandler={() => {
                                            if (!isSubminted && checkedValue) {
                                              if (
                                                user &&
                                                user?.userRole ===
                                                  UserRole.ADMIN
                                              ) {
                                                canceledTransaction(
                                                  transaction.data,
                                                  transaction.id
                                                );
                                                setCurentTransaction({
                                                  transaction: transaction.data,
                                                  id: transaction.id,
                                                });
                                              }
                                            } else {
                                              toast.error(
                                                "Vous ne pouvez pas annuler cette transaction"
                                              );
                                            }
                                          }}
                                          rowBlockedTransHandler={() => {
                                            if (
                                              transaction.data.status !==
                                              TransactionStatus.BLOCKED
                                            ) {
                                              if (
                                                agencyAgent ||
                                                user?.userRole ===
                                                  UserRole.TECHNICALSUPPORT ||
                                                user?.userRole ===
                                                  UserRole.ADMIN
                                              ) {
                                                setShowDeadlineModal(true);
                                                setCurentTransaction({
                                                  transaction: transaction.data,
                                                  id: transaction.id,
                                                });
                                              }
                                            } else
                                              toast.error(
                                                "La transactions a été déjà blocker."
                                              );
                                          }}
                                          rowReturnTransHandler={() => {
                                            if (!isSubminted && checkedValue) {
                                              returnTransaction(
                                                transaction.data,
                                                transaction.id
                                              );
                                            } else {
                                              toast.error(
                                                "Vous ne pouvez pas restituer cette transaction"
                                              );
                                            }
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <NoContent />
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <table
                            className="min-w-full border-separate"
                            style={{ borderSpacing: 0 }}
                          >
                            <thead className="bg-gray-50">
                              <tr>
                                {pendingTransactionTableHeader.map(
                                  (th, index) => (
                                    <th
                                      key={index}
                                      scope="col"
                                      className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                    >
                                      {th}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {pendingTransactions &&
                              pendingTransactions.length > 0 ? (
                                pendingTransactions.map(
                                  (
                                    transaction: any,
                                    transactionIdx: number
                                  ) => (
                                    <tr key={transactionIdx}>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            pendingTransactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {(transactionIdx as number) + 1}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            pendingTransactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {(
                                          transaction.data
                                            .createdAt as Timestamp
                                        )
                                          .toDate()
                                          .toLocaleString()}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            pendingTransactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {transaction.id}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            pendingTransactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {transaction.data.receiverPhoneNumber}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {new Intl.NumberFormat().format(
                                          transaction.data.amount
                                        )}{" "}
                                        Fcfa
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            pendingTransactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {transaction.data.senderPhoneNumber}
                                      </td>
                                      <td
                                        className={classNames(
                                          transactionIdx !==
                                            transactions.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                                        )}
                                      >
                                        <TableRowEditMenu
                                          rowCancelledPendingTransHandler={() => {
                                            canceledPendingTransaction(
                                              transaction.data,
                                              transaction.id
                                            );
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <NoContent />
                              )}
                            </tbody>
                          </table>
                        )}

                        {/* {!checkedValue &&
                          transactions &&
                          transactions.length > 0 && (
                            <Pagination
                              skip={defaultLoadHistories}
                              take={defaultLoadHistories}
                              total={defaultLoadHistories}
                              collectionName={collectionName}
                              isChangedPage={false}
                              onPageChange={(pageIndex: number) =>
                                pageIndex * page
                              }
                              onPageLimitChange={(pageLimit: number) =>
                                onPageLimitChange(pageLimit)
                              }
                              balance={transactionTotalSum}
                            />
                          )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={showDeadlineModal}
            maxSize="md"
            closeModal={() => setShowDeadlineModal(false)}
            modalTitle="Opération irréversible"
          >
            <Form<{ description: string; deadline: string }>
              onSubmit={blockedTransaction}
              form={{
                resolver: yupResolver(
                  yup.object().shape({
                    description: yup
                      .string()
                      .required("Le champs est obligatoire"),
                    deadline: yup
                      .string()
                      .required("Le champs est obligatoire"),
                  })
                ),
                defaultValues: {
                  description: "",
                  deadline: "",
                },
              }}
              submitButtonLabel={"Bloquer"}
            >
              <div className="col-span-6">
                <FormInput
                  name="description"
                  label="Motif"
                  placeholder="Numéro incorecte"
                />
                <FormInput
                  name="deadline"
                  label="Delai"
                  type="text"
                  placeholder="24h"
                />
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <ReactTooltip
        id="retrive_trans_by_id"
        place="bottom"
        variant="info"
        content="Retrouvez la transaction avec son ID uniquement"
      />
      <ReactTooltip
        id="retrive_pending_trans_by_code"
        place="bottom"
        variant="info"
        content="Retrouvez la transaction avec son code de retrait"
      />
    </>
  );
};

export default TransationPage;
