import { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import CustomToaster from "./components/CustomToaster";
import DestructionConfirmModal, {
  DestructiveConfirmContext,
  InitialConfirmState,
} from "./components/DestructionConfirmModal";
import ErrorPage from "./components/ErrorPage";
import Root from "./components/Root";
import { UserContext } from "./contexts";
import useFindUser from "./hooks/useFindUser";
import { Account, Profile, Security } from "./pages/Account";
import AgentsPage from "./pages/Agents";
import EditAgent from "./pages/Agents/Agent";
import Dashboard from "./pages/Dashboard";
import DealersPage from "./pages/Dealers";
import EditDealer from "./pages/Dealers/Dealer";
import HistoriquesPage from "./pages/Historiques";
import TransactionHistoriqueDetailsPage from "./pages/Historiques/Historique";
import NotificationsPage from "./pages/Notifications";
import UsersPage from "./pages/Users";
import EditUserPage from "./pages/Users/User";
import OrdersPage from "./pages/GasStationManagement/Orders";
import WithdrawMoneyPage from "./pages/WithrawMoney";
import AuditLogs from "./pages/Account/AuditLogs";
import BulkPayment from "./pages/BulkPayment";
import ContactsList from "./pages/BulkPayment/Contacts";
import EditContact from "./pages/BulkPayment/Contacts/Contact";
import GasStationPayment from "./pages/Payment/GasStation";
import MerchantPayment from "./pages/Payment/Merchant";
import PaymentPage from "./pages/Payment/Payment";
import MerchantsPage from "./pages/Merchants";
import SankPartenairsWithdrawMoneyPage from "./pages/WithrawMoney/PartenairesWithdraw";
import ReloadDealerOrAgentMoneyPage from "./pages/TranferMoney/AdminReloadAgentOrDealer";
import ReloadPage from "./pages/TranferMoney/ReloadAccount";
import TransferPage from "./pages/TranferMoney/Transfer";
import TransfertUserToUser from "./pages/TranferMoney/UserTransferMoneyToUser";
import WithdrawUserMoney from "./pages/WithrawMoney/WithdrawUserMoney";
import ReloadAgencyAccount from "./pages/TranferMoney/ReloadAgencyAccount";
import AgentDetailsPage from "./pages/Agents/AgentPage";
import DealeryDetailsPage from "./pages/Dealers/DealerDetailsPage";
import MerchantDetailsPage from "./pages/Merchants/MerchantDetailsPage";
import UserDetailsPage from "./pages/Users/UserDetailsPage";
import AllSankStaffPage from "./pages/AgenciesManagement/Staff";
import AllAgenciesPage from "./pages/AgenciesManagement/Agencies/Agencies";
import EditAgencyPage from "./pages/AgenciesManagement/Agencies/Agency";
import AgencyDetails from "./pages/AgenciesManagement/Agencies/Staff";
import AgencyStaffEditPage from "./pages/AgenciesManagement/Staff/Staff/staff";
import StaffDetailsPage from "./pages/AgenciesManagement/Staff/StaffDetailsPage";
import AllGasStationListPage from "./pages/GasStationManagement/GasStations";
import EditGasStationPage from "./pages/GasStationManagement/GasStations/GasStation/EditGasStationPage";
import GasStationAllStaffListPage from "./pages/GasStationManagement/Staff";
import GasStationStaffDetailsPape from "./pages/GasStationManagement/Staff/GasStationStaffDetailsPape";
import GasStationStaffEditPage from "./pages/GasStationManagement/Staff/Staff/Staff";
import GasStationDetailsPage from "./pages/GasStationManagement/GasStations/GasStationDetailsPage";
import LoginWithEmail from "./components/Auth/Login/LoginWithEmail";
import AuthRoute from "./components/privateRoute";
import Pages403ForbiddenPage from "./components/403Pages";
import WithdrawWithCode from "./pages/WithrawMoney/WithdrawWithCode";
import TransationPage from "./pages/Transactions";
import MakeUserAMerchant from "./pages/Merchants/Merchant";
import MakeUserAnAgent from "./pages/Agents/Agent/MakeUserAnAgent";
import OrderDetailPage from "./pages/GasStationManagement/Orders/Order";
import MakeUserDealer from "./pages/Dealers/MakeUserDealer";
import TransactionsAuditLogs from "./pages/Admin/AuditLogs/Transactions";
import AdminAuditLogsPage from "./pages/Admin/AuditLogs";
import AdminAuditLogs from "./pages/Admin/AuditLogs/AdminAuditLogs";
import LoginWithOtpCode from "./components/Auth/Login/LoginWithOtpCode";
import ReloadSankMoneyAccountItemsPage from "./pages/ReloadSankMoneyAccount";
import ReloadSankMoneyAccount from "./pages/ReloadSankMoneyAccount/Reload";
import AddReviewer from "./pages/ReloadSankMoneyAccount/AddReviewer";
import AddReloadDealerAccountReviewer from "./pages/ReloadDealerAccount/AddReviewer";
import ReloadSankMoneyDealerAccount from "./pages/ReloadDealerAccount/Reload";
import ReloadSankMoneyDealerAccountItemsPage from "./pages/ReloadDealerAccount";
import SankAgentsWithdrawMoneyPage from "./pages/WithrawMoney/WithdrawAgentMoneyToDealer";
import MembersAuditLogs from "./pages/AgenciesManagement/AuditLogs";
import UserAffiliatedAgentPages from "./pages/Agents/Users";
import Settings from "./pages/Admin/Settings";
import RettrivePaidCommission from "./pages/Admin/Settings/PaidCommission/RettrivePaidCommission";
import AdminManageCommission from "./pages/Admin/Settings/PaidCommission";
import UserAffiliatedAgentEditPage from "./pages/Agents/Users/User";
import AgentCreateUserHistories from "./pages/Admin/AuditLogs/AgentCreateUserHistories";
import SankWithdrawGasStationBalancePage from "./pages/WithrawMoney/WithdrawGasStationBalance";
import MerchandPaymentWithCard from "./pages/Payment/Merchant/MerchandPaymentWithCard";
import Moment from "react-moment";
import "moment/locale/fr";
import GasStationPaymentWithCard from "./pages/Payment/GasStation/GasStationPaymentWithCard";
import DebitCard from "./pages/Cards/All";
import MerchantEditPage from "./pages/Merchants/Merchant/MerchantEditPage";
import AssignCard from "./pages/Cards/All/AssignCard";
import CardDetails from "./pages/Cards/All/CardDetails";
import CardTranactionHistories from "./pages/Cards/All/cardTranactionHistories";
import SankWithdrawMerchantPage from "./pages/WithrawMoney/WithdrawMerchantBalance";
import MoveFeesToSankMainAccount from "./pages/Admin/Settings/MoveAmountToSankMainAccount/TransactionsFees";
import ReferralHistoriesPage from "./pages/Referrals";
import SuperMerchantPage from "./pages/SuperMerchant";
import EditSuperMerchant from "./pages/SuperMerchant/EditSuperMerchant";
import SuperMerchantDetails from "./pages/SuperMerchant/SuperMerchantDetails";
import SuperMerchantHomePage from "./pages/SuperMerchant/Home";
import { UserRole } from "./interfaces";
import AffiliateMerchants from "./pages/SuperMerchant/AffiliateMerchants";
import MerchantTransactions from "./pages/SuperMerchant/MerchantTransactions";
import MerchantDetails from "./pages/SuperMerchant/MerchantDetails";
import SuperMerchantsAllTransactions from "./pages/SuperMerchant/SuperMerchantsAllTransactions";
import ReferralDetailsPage from "./pages/Referrals/ReferralDetails";
import SellerReferralDetailsPage from "./pages/Referrals/sellerReferralDetails"
import CardsSellesLogs from "./pages/Admin/AuditLogs/CardsSoldLogs";
import AllCompaniesPage from "./pages/Companies/Companies";
import CompanyEditPage from "./pages/Companies/Companies/Company";
import CompanyDetailsPage from "./pages/Companies/Companies/CompanyDetails";
import CompanyAllStaffListPage from "./pages/Companies/CompanyStaff";
import CompanyContactsList from "./pages/Companies/Contacts";
import AddCompanyContact from "./pages/Companies/Contacts/Contact";
import CompanyBulkPaiement from "./pages/Companies/Paiement";
import ReportCompanyTransactions from "./pages/Companies/ReportTransactions";
import ReportTransactionsItemsPage from "./pages/Companies/ReportTransactions/ReportTransactionsItemsPage";
import CertifyUserPage from "./pages/Users/User/CertifyUserPage";
import DealerAgentPage from "./pages/Dealers/DealerAgentPage";
import CompanyStaffDetailsPage from "./pages/Companies/CompanyStaff/CompanyStaffDetailsPage";
import Invoices from "./pages/Factures";
import SNBLInvoices from "./pages/Factures/SNBL";
import PrePaidInvoicesManageInvoices from "./pages/Factures/SNBL/PaidPrePaidInvoices/PrePaidInvoicesManageInvoices";
import PostPaidInvoicesManageInvoices from "./pages/Factures/SNBL/PaidPostPaidInvoices/PostPaidInvoicesManageInvoices";
import AgencyReloadCompaniesPage from "./pages/TranferMoney/AgencyReloadCompanies";
import AdminRefundUserMoney from "./pages/Admin/Settings/RefundMoney";
import SnblUnpaidPrepaidInvoice from "./pages/Admin/Settings/RefundMoney/SnblUnpaidPrepaidInvoice";
import ManageOneaInvoicesPage from "./pages/Factures/Onea/ManageOneaInvoicesPage";
import AggrivoucherPayment from "./pages/Factures/Aggrivoucher/Payment/Payment";
import ManageAgriInvoicesPage from "./pages/Factures/Aggrivoucher";
import AdminBulkPayment from "./pages/Admin/Settings/BulkPayment";
import WithdrawAgencyAccountBalance from "./pages/TranferMoney/WithdrawAgencyAccountBalance";
import SnblUnpaidOneaPostpaidInvoice from "./pages/Admin/Settings/RefundMoney/SnblUnpaidOneaPostpaidInvoice";
import SnblUnpaidPostpaidInvoice from "./pages/Admin/Settings/RefundMoney/SnblUnpaidPostpaidInvoice";
import AggrivoucherUnpaidMoney from "./pages/Admin/Settings/RefundMoney/AggrivoucherUnpaid";
import MouvAmountToMainAccount from "./pages/Admin/Settings/MoveAmountToSankMainAccount";
import MoveSNBLPostpaidTransFeesToSankMainAccount from "./pages/Admin/Settings/MoveAmountToSankMainAccount/Sonabel/postpaid";
import MoveSNBLPrepaidTransFeesToSankMainAccount from "./pages/Admin/Settings/MoveAmountToSankMainAccount/Sonabel/prepaid";
import MoveAggrivoucherTransFeesToSankMainAccount from "./pages/Admin/Settings/MoveAmountToSankMainAccount/Aggrivoucher";
import MoveONEATransFeesToSankMainAccount from "./pages/Admin/Settings/MoveAmountToSankMainAccount/ONEA";
import RefundAirtimeTransaction from "./pages/Admin/Settings/RefundMoney/Airtime";
import ReportingBatchBulkPayment from "./pages/Companies/ReportingBatchBulkPayment";
import SuperStations from "./pages/SuperStation/SuperStations";
import EditSuperStation from "./pages/SuperStation/EditSuperStation";
import SuperStationsAllTransactions from "./pages/SuperStation/SuperStationsAllTransactions";
import SuperStationDetails from "./pages/SuperStation/SuperStationDetails";
import AffiliateStations from "./pages/SuperStation/AffiliateStations";
import SuperStationHomePage from "./pages/SuperStation/Home";
import StationTransactions from "./pages/SuperStation/StationTransactions";
import StationDetails from "./pages/SuperStation/StationDetails";
import CompanyStaffEdit from "./pages/Companies/CompanyStaff/CompanyStaff";

function App() {
  Moment.globalLocale = "fr";

  const [showDestructiveConfirm, setShowDestructiveConfirm] =
    useState<boolean>(false);
  const [destructiveConfirmState, setDestructiveConfirmState] =
    useState(InitialConfirmState);
  const {
    user,
    setUser,
    isLoading,
    userAccounts,
    setUserAccounts,
    setUserNotifications,
    userNotifications,
    agencyAgent,
    setAgencyAgent,
    setIsLoading,
    gasStationInfo,
    setGasStationInfo,
    agentCreateUserParams,
    setAgentCreateUserParams,
    superMerchantAffiliatedMerchants,
    setSuperMerchantAffiliatedMerchants,
    merchantAffiliatedToSuperMerchant,
    setMerchantAffiliatedToSuperMerchant,
    company,
    setCompany,
    setStationAffiliatedToSuperStation,
    setSuperStationAffiliatedStations,
    stationAffiliatedToSuperStation,
    superStationAffiliatedStations,
  } = useFindUser();
  return (
    <>
      <DestructiveConfirmContext.Provider
        value={{
          show: showDestructiveConfirm,
          setShow: setShowDestructiveConfirm,
          confirmState: destructiveConfirmState,
          setConfirmState: setDestructiveConfirmState,
        }}
      >
        <UserContext.Provider
          value={{
            agencyAgent: agencyAgent,
            setAgencyAgent: setAgencyAgent,
            user: user,
            setUser: setUser,
            userAccounts: userAccounts,
            userNotification: userNotifications,
            gasStationInfo: gasStationInfo,
            agentCreateUserParams: agentCreateUserParams,
            setUserAccounts: setUserAccounts,
            setUserNotification: setUserNotifications,
            isLoading: isLoading,
            setIsLoading: setIsLoading,
            setGasStationInfo: setGasStationInfo,
            setAgentCreateUserParams: setAgentCreateUserParams,
            superMerchantAffiliatedMerchants,
            setSuperMerchantAffiliatedMerchants:
              setSuperMerchantAffiliatedMerchants,
            merchantAffiliatedToSuperMerchant,
            setMerchantAffiliatedToSuperMerchant,
            company,
            setCompany,
            setStationtAffiliatedToSuperStationt:
              setStationAffiliatedToSuperStation,
            setSuperStationAffiliatedStations,
            stationAffiliatedToSuperStation,
            superStationAffiliatedStations,
          }}
        >
          <Routes>
            <Route
              path="/login-with-email"
              element={
                <AuthRoute
                  path="/login-with-email"
                  userRole={[
                    "ADMIN",
                    "AGENCYSTAFF",
                    "AGENT",
                    "USER",
                    "AGENCY",
                    "GASSTATION",
                    "DEALER",
                    "MERCHANT",
                    "GASSTATIONSTAFF",
                    "SUDO",
                    "SUPERVISOR",
                    "COMPANY",
                    "COMPANYSTAFF",
                    "TECHNICALSUPPORT",
                  ]}
                >
                  <LoginWithEmail />
                </AuthRoute>
              }
            ></Route>
            <Route
              path="/login-with-otp"
              element={
                <AuthRoute
                  path="/login-with-otp"
                  userRole={[
                    "ADMIN",
                    "AGENCYSTAFF",
                    "AGENT",
                    "USER",
                    "AGENCY",
                    "GASSTATION",
                    "DEALER",
                    "MERCHANT",
                    "GASSTATIONSTAFF",
                    "SUDO",
                    "SUPERVISOR",
                    "COMPANY",
                    "COMPANYSTAFF",
                    "TECHNICALSUPPORT",
                  ]}
                >
                  <LoginWithOtpCode />
                </AuthRoute>
              }
            ></Route>
            <Route
              path="/"
              element={
                <AuthRoute
                  path="/"
                  userRole={[
                    "ADMIN",
                    "AGENCYSTAFF",
                    "AGENT",
                    "USER",
                    "AGENCY",
                    "GASSTATION",
                    "DEALER",
                    "MERCHANT",
                    "GASSTATIONSTAFF",
                    "SUDO",
                    "SUPERVISOR",
                    "SUPERMERCHANT",
                    "SUPERSTATION",
                    "COMPANY",
                    "COMPANYSTAFF",
                    "TECHNICALSUPPORT",
                  ]}
                >
                  <Root />
                </AuthRoute>
              }
              errorElement={<ErrorPage />}
            >
              <Route
                path="/"
                element={
                  <AuthRoute
                    path="/"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENT",
                      "USER",
                      "AGENCY",
                      "GASSTATION",
                      "DEALER",
                      "MERCHANT",
                      "GASSTATIONSTAFF",
                      "SUDO",
                      "SUPERVISOR",
                      "SUPERMERCHANT",
                      "SUPERSTATION",
                      "COMPANY",
                      "COMPANYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    {user && user.userRole === UserRole.SUPERMERCHANT ? (
                      <SuperMerchantHomePage />
                    ) : user && user.userRole === UserRole.SUPERSTATION ? (
                      <SuperStationHomePage />
                    ) : (
                      <Dashboard />
                    )}
                  </AuthRoute>
                }
              />
              <Route path="forbidden" element={<Pages403ForbiddenPage />} />
              <Route
                path="account"
                element={
                  <AuthRoute
                    path="/account"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENT",
                      "USER",
                      "AGENCY",
                      "GASSTATION",
                      "DEALER",
                      "MERCHANT",
                      "GASSTATIONSTAFF",
                      "SUDO",
                      "SUPERVISOR",
                      "SUPERMERCHANT",
                      "SUPERSTATION",
                      "COMPANY",
                      "COMPANYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <Account />
                  </AuthRoute>
                }
              >
                <Route
                  path="profile"
                  element={
                    <AuthRoute
                      path="/account/profile"
                      userRole={[
                        "ADMIN",
                        "AGENCYSTAFF",
                        "AGENT",
                        "USER",
                        "AGENCY",
                        "GASSTATION",
                        "DEALER",
                        "MERCHANT",
                        "GASSTATIONSTAFF",
                        "SUDO",
                        "SUPERVISOR",
                        "SUPERMERCHANT",
                        "SUPERSTATION",
                        "COMPANY",
                        "COMPANYSTAFF",
                        "TECHNICALSUPPORT",
                      ]}
                    >
                      <Profile />
                    </AuthRoute>
                  }
                />
                <Route
                  path="security"
                  element={
                    <AuthRoute
                      path="/account/security"
                      userRole={[
                        "ADMIN",
                        "AGENCYSTAFF",
                        "AGENT",
                        "USER",
                        "AGENCY",
                        "GASSTATION",
                        "DEALER",
                        "MERCHANT",
                        "GASSTATIONSTAFF",
                        "SUDO",
                        "SUPERVISOR",
                        "SUPERMERCHANT",
                        "SUPERSTATION",
                        "COMPANY",
                        "COMPANYSTAFF",
                        "TECHNICALSUPPORT",
                      ]}
                    >
                      <Security />
                    </AuthRoute>
                  }
                />
                <Route
                  path="auditLogs"
                  element={
                    <AuthRoute
                      path="/account/security"
                      userRole={[
                        "ADMIN",
                        "AGENCYSTAFF",
                        "AGENT",
                        "USER",
                        "AGENCY",
                        "GASSTATION",
                        "DEALER",
                        "MERCHANT",
                        "GASSTATIONSTAFF",
                        "SUDO",
                        "SUPERVISOR",
                        "SUPERMERCHANT",
                        "SUPERSTATION",
                        "COMPANY",
                        "COMPANYSTAFF",
                        "TECHNICALSUPPORT",
                      ]}
                    >
                      <AuditLogs />
                    </AuthRoute>
                  }
                />
              </Route>
              <Route
                path="audits"
                element={
                  <AuthRoute
                    path="/audits"
                    userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}
                  >
                    <AdminAuditLogsPage />
                  </AuthRoute>
                }
              >
                <Route
                  path="logs"
                  element={
                    <AuthRoute path="/audits/logs" userRole={["ADMIN"]}>
                      <AdminAuditLogs />
                    </AuthRoute>
                  }
                />
                <Route
                  path="transactions"
                  element={
                    <AuthRoute path="/audits/transactions" userRole={["ADMIN"]}>
                      <TransactionsAuditLogs />
                    </AuthRoute>
                  }
                />
                <Route
                  path="agent_create_user_histories"
                  element={
                    <AuthRoute
                      path="/audits/agent_create_user_histories"
                      userRole={["ADMIN"]}
                    >
                      <AgentCreateUserHistories />
                    </AuthRoute>
                  }
                />
                <Route
                  path="selle_cards_histories"
                  element={
                    <AuthRoute
                      path="/audits/selle_cards_histories"
                      userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}
                    >
                      <CardsSellesLogs />
                    </AuthRoute>
                  }
                />
              </Route>
              <Route
                path="settings"
                element={
                  <AuthRoute path="/settings" userRole={["ADMIN"]}>
                    <Settings />
                  </AuthRoute>
                }
              >
                {/* <Route
                  path="agent_create_user_params"
                  element={
                    <AuthRoute
                      path="/settings/agent_create_user_params"
                      userRole={["ADMIN"]}
                    >
                      <AgentCreateUserParams />
                    </AuthRoute>
                  }
                /> */}
                <Route
                  path="mouv_balance"
                  element={
                    <AuthRoute
                      path="/settings/mouv_balance"
                      userRole={["ADMIN"]}
                    >
                      <MouvAmountToMainAccount />
                    </AuthRoute>
                  }
                >
                  <Route
                    path="transactions_fees"
                    element={
                      <AuthRoute
                        path="/settings/mouv_balance/transactions_fees"
                        userRole={["ADMIN"]}
                      >
                        <MoveFeesToSankMainAccount />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="snbl/postpaid_transactions_fees"
                    element={
                      <AuthRoute
                        path="/settings/mouv_balance/snbl/postpaid_transactions_fees"
                        userRole={["ADMIN"]}
                      >
                        <MoveSNBLPostpaidTransFeesToSankMainAccount />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="snbl/prepaid_transactions_fees"
                    element={
                      <AuthRoute
                        path="/settings/mouv_balance/snbl/prepaid_transactions_fees"
                        userRole={["ADMIN"]}
                      >
                        <MoveSNBLPrepaidTransFeesToSankMainAccount />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="aggrivoucher_transactions_fees"
                    element={
                      <AuthRoute
                        path="/settings/mouv_balance/aggrivoucher_transactions_fees"
                        userRole={["ADMIN"]}
                      >
                        <MoveAggrivoucherTransFeesToSankMainAccount />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="onea_transactions_fees"
                    element={
                      <AuthRoute
                        path="/settings/mouv_balance/onea_transactions_fees"
                        userRole={["ADMIN"]}
                      >
                        <MoveONEATransFeesToSankMainAccount />
                      </AuthRoute>
                    }
                  />
                </Route>
                <Route
                  path="commissions"
                  element={
                    <AuthRoute
                      path="/settings/commissions"
                      userRole={["ADMIN"]}
                    >
                      <AdminManageCommission />
                    </AuthRoute>
                  }
                >
                  {/* <Route
                    path="paid_commission"
                    element={
                      <AuthRoute
                        path="/settings/commissions/paid_commission"
                        userRole={["ADMIN"]}
                      >
                        <PaidCommission />
                      </AuthRoute>
                    }
                  /> */}
                  <Route
                    path="paid_commission_history"
                    element={
                      <AuthRoute
                        path="/settings/commissions/paid_commission_history"
                        userRole={["ADMIN"]}
                      >
                        <RettrivePaidCommission />
                      </AuthRoute>
                    }
                  />
                </Route>
                <Route
                  path="refund"
                  element={
                    <AuthRoute path="/settings/refund" userRole={["ADMIN"]}>
                      <AdminRefundUserMoney />
                    </AuthRoute>
                  }
                >
                  <Route
                    path="snbl_prepaid"
                    element={
                      <AuthRoute
                        path="/settings/refund/snbl_prepaid"
                        userRole={["ADMIN"]}
                      >
                        <SnblUnpaidPrepaidInvoice />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="snbl_postpaid"
                    element={
                      <AuthRoute
                        path="/settings/refund/snbl_postpaid"
                        userRole={["ADMIN"]}
                      >
                        <SnblUnpaidPostpaidInvoice />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="onea_postpaid"
                    element={
                      <AuthRoute
                        path="/settings/refund/onea_postpaid"
                        userRole={["ADMIN"]}
                      >
                        <SnblUnpaidOneaPostpaidInvoice />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="aggrivoucher_unpaid"
                    element={
                      <AuthRoute
                        path="/settings/refund/aggrivoucher_unpaid"
                        userRole={["ADMIN"]}
                      >
                        <AggrivoucherUnpaidMoney />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="airtime_transaction"
                    element={
                      <AuthRoute
                        path="/settings/refund/airtime_transaction"
                        userRole={["ADMIN"]}
                      >
                        <RefundAirtimeTransaction />
                      </AuthRoute>
                    }
                  />
                </Route>
                <Route
                  path="bulk_paiement"
                  element={
                    <AuthRoute
                      path="/settings/bulk_paiement"
                      userRole={["ADMIN"]}
                    >
                      <AdminBulkPayment />
                    </AuthRoute>
                  }
                />
              </Route>
              {
                //===========================CARD=======================//
              }
              <Route
                path="cards"
                element={
                  <AuthRoute path="/cards" userRole={["ADMIN", "USER", "SUDO"]}>
                    <DebitCard />
                  </AuthRoute>
                }
              ></Route>
              <Route
                path="cards/:cardId/assign-card"
                element={
                  <AuthRoute
                    path="/cards/:cardId/assign-card"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "SUDO",
                      "AGENT",
                      "MERCHANT",
                      "USER",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AssignCard />
                  </AuthRoute>
                }
              />
              <Route
                path="cards/:card/deatils"
                element={
                  <AuthRoute
                    path="/cards/:card/deatils"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "SUDO",
                      "AGENT",
                      "USER",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <CardDetails />
                  </AuthRoute>
                }
              />

              <Route
                path="cards/:card/histories"
                element={
                  <AuthRoute
                    path="/cards/:card/histories"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "SUDO",
                      "AGENT",
                      "USER",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <CardTranactionHistories />
                  </AuthRoute>
                }
              />
              {
                //===========================End CARD=======================//
              }
              <Route
                path="transfer"
                element={
                  <AuthRoute
                    path="/transfer"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENCY",
                      "AGENT",
                      "USER",
                      "DEALER",
                    ]}
                  >
                    <TransferPage />
                  </AuthRoute>
                }
              >
                <Route
                  path="transfer_to_user"
                  element={
                    <AuthRoute
                      path="/transfer/transfer_to_user"
                      userRole={["ADMIN", "USER"]}
                    >
                      <TransfertUserToUser />
                    </AuthRoute>
                  }
                />
                <Route
                  path="reload_user"
                  element={
                    <AuthRoute
                      path="/transfer/reload_user"
                      userRole={[
                        "ADMIN",
                        "AGENCYSTAFF",
                        "AGENCY",
                        "AGENT",
                        "DEALER",
                      ]}
                    >
                      <ReloadPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/transfer/reload_agency"
                  element={
                    <AuthRoute path="reload_agency" userRole={["ADMIN"]}>
                      <ReloadAgencyAccount />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/transfer/withdraw_agency"
                  element={
                    <AuthRoute path="withdraw_agency" userRole={["ADMIN"]}>
                      <WithdrawAgencyAccountBalance />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/transfer/reload_company"
                  element={
                    <AuthRoute
                      path="reload_company"
                      userRole={["ADMIN", "SUDO", "AGENCYSTAFF"]}
                    >
                      <AgencyReloadCompaniesPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="reload"
                  element={
                    <AuthRoute
                      path="/transfer/reload"
                      userRole={["SUDO", "DEALER", "AGENCY", "AGENCYSTAFF"]}
                    >
                      <ReloadDealerOrAgentMoneyPage />
                    </AuthRoute>
                  }
                />
              </Route>

              <Route
                path="payment"
                element={
                  <AuthRoute
                    path="/payment"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENT",
                      "USER",
                      "AGENCY",
                      "DEALER",
                    ]}
                  >
                    <PaymentPage />
                  </AuthRoute>
                }
              >
                <Route
                  path="merchant"
                  element={
                    <AuthRoute path="/merchant" userRole={["ADMIN", "USER"]}>
                      <MerchantPayment />
                    </AuthRoute>
                  }
                />
                <Route
                  path="gas-station"
                  element={
                    <AuthRoute path="/gas-station" userRole={["ADMIN", "USER"]}>
                      <GasStationPayment />
                    </AuthRoute>
                  }
                />
              </Route>
              <Route
                path="gas-station/payment_with_card"
                element={
                  <AuthRoute
                    path="/gas-station/payment_with_card"
                    userRole={["ADMIN", "GASSTATIONSTAFF"]}
                  >
                    <GasStationPaymentWithCard />
                  </AuthRoute>
                }
              />

              <Route
                path="withdraw"
                element={
                  <AuthRoute
                    path="/withdraw"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENT",
                      "AGENCY",
                      "USER",
                      "DEALER",
                    ]}
                  >
                    <WithdrawMoneyPage />
                  </AuthRoute>
                }
              />
              <Route
                path="partenaires_withdraw"
                element={
                  <AuthRoute
                    path="/partenaires_withdraw"
                    userRole={["ADMIN", "AGENT", "DEALER"]}
                  >
                    <SankPartenairsWithdrawMoneyPage />
                  </AuthRoute>
                }
              />
              <Route
                path="withdraw_agent"
                element={
                  <AuthRoute
                    path="/withdraw_agent"
                    userRole={["ADMIN", "AGENT"]}
                  >
                    <SankAgentsWithdrawMoneyPage />
                  </AuthRoute>
                }
              />
              <Route
                path="withdraw_gassation"
                element={
                  <AuthRoute
                    path="/withdraw_gassation"
                    userRole={["ADMIN", "GASSTATIONSTAFF"]}
                  >
                    <SankWithdrawGasStationBalancePage />
                  </AuthRoute>
                }
              />
              <Route
                path="withdraw_user"
                element={
                  <AuthRoute
                    path="/withdraw_user"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENT",
                      "AGENCY",
                      "USER",
                      "DEALER",
                    ]}
                  >
                    <WithdrawUserMoney />
                  </AuthRoute>
                }
              />

              <Route
                path={
                  user && user.userRole === UserRole.SUPERMERCHANT
                    ? "superMerchants/home"
                    : user && user.userRole === UserRole.SUPERSTATION
                    ? "superStation/home"
                    : "dashboard"
                }
                element={
                  <AuthRoute
                    path={
                      user && user.userRole === UserRole.SUPERMERCHANT
                        ? "superMerchants/home"
                        : user && user.userRole === UserRole.SUPERSTATION
                        ? "superStation/home"
                        : "dashboard"
                    }
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENT",
                      "USER",
                      "AGENCY",
                      "GASSTATION",
                      "DEALER",
                      "MERCHANT",
                      "GASSTATIONSTAFF",
                      "SUDO",
                      "SUPERVISOR",
                      "SUPERMERCHANT",
                      "SUPERSTATION",
                      "COMPANY",
                      "COMPANYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    {user && user.userRole === UserRole.SUPERMERCHANT ? (
                      <SuperMerchantHomePage />
                    ) : user && user.userRole === UserRole.SUPERSTATION ? (
                      <SuperStationHomePage />
                    ) : (
                      <Dashboard />
                    )}
                  </AuthRoute>
                }
              />
              <Route
                path="reload_sank_money_account"
                element={
                  <AuthRoute
                    path="/reload_sank_money_account"
                    userRole={["ADMIN"]}
                  >
                    <ReloadSankMoneyAccountItemsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="reload_sank_money_account/new"
                element={
                  <AuthRoute
                    path="/reload_sank_money_account/new"
                    userRole={["ADMIN"]}
                  >
                    <ReloadSankMoneyAccount />
                  </AuthRoute>
                }
              />
              <Route
                path="reload_sank_money_account/add_reviewer"
                element={
                  <AuthRoute
                    path="/reload_sank_money_account/add_reviewer"
                    userRole={["ADMIN"]}
                  >
                    <AddReviewer />
                  </AuthRoute>
                }
              />
              <Route
                path="reload_sank_money_dealer_account"
                element={
                  <AuthRoute
                    path="/reload_sank_money_dealer_account"
                    userRole={["ADMIN"]}
                  >
                    <ReloadSankMoneyDealerAccountItemsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="reload_sank_money_dealer_account/new"
                element={
                  <AuthRoute
                    path="/reload_sank_money_dealer_account/new"
                    userRole={["ADMIN"]}
                  >
                    <ReloadSankMoneyDealerAccount />
                  </AuthRoute>
                }
              />
              <Route
                path="reload_sank_money_dealer_account/add_reviewer"
                element={
                  <AuthRoute
                    path="/reload_sank_money_dealer_account/add_reviewer"
                    userRole={["ADMIN"]}
                  >
                    <AddReloadDealerAccountReviewer />
                  </AuthRoute>
                }
              />
              <Route
                path="bulkPayment"
                element={
                  <AuthRoute
                    path="/bulkPayment"
                    userRole={["ADMIN", "AGENCY", "AGENCYSTAFF", "USER"]}
                  >
                    <ContactsList />
                  </AuthRoute>
                }
              />
              <Route
                path="payment_with_card"
                element={
                  <AuthRoute path="/payment_with_card" userRole={["MERCHANT"]}>
                    <MerchandPaymentWithCard />
                  </AuthRoute>
                }
              />
              <Route
                path="bulkPayment/payment"
                element={
                  <AuthRoute
                    path="/bulkPayment/payment"
                    userRole={["ADMIN", "AGENCY", "AGENCYSTAFF", "USER"]}
                  >
                    <BulkPayment />
                  </AuthRoute>
                }
              />
              <Route
                path="bulkPayment/contacts/new"
                element={
                  <AuthRoute
                    path="/bulkPayment/contacts/new"
                    userRole={["ADMIN", "AGENCY", "AGENCYSTAFF", "USER"]}
                  >
                    <EditContact />
                  </AuthRoute>
                }
              />
              <Route
                path="bulkPayment/contacts/:contact/edit"
                element={
                  <AuthRoute
                    path="/bulkPayment/contacts/:contact/edit"
                    userRole={["ADMIN", "AGENCY", "AGENCYSTAFF", "USER"]}
                  >
                    <EditContact />
                  </AuthRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <AuthRoute
                    path="/notifications"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENT",
                      "USER",
                      "AGENCY",
                      "GASSTATION",
                      "AGENCY",
                      "DEALER",
                      "MERCHANT",
                      "GASSTATIONSTAFF",
                      "SUDO",
                      "SUPERVISOR",
                      "COMPANYSTAFF",
                      "SUPERMERCHANT",
                      "SUPERSTATION",
                      "COMPANY",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <NotificationsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="historiques"
                element={
                  <AuthRoute
                    path="/historiques"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENT",
                      "USER",
                      "AGENCY",
                      "GASSTATION",
                      "DEALER",
                      "AGENCY",
                      "MERCHANT",
                      "GASSTATIONSTAFF",
                      "SUDO",
                      "SUPERMERCHANT",
                      "SUPERSTATION",
                      "COMPANY",
                      "COMPANYSTAFF",
                    ]}
                  >
                    <HistoriquesPage />
                  </AuthRoute>
                }
              />
              <Route
                path="transactions/:transaction/detail"
                element={
                  <AuthRoute
                    path="/transactions/:transaction/detail"
                    userRole={[
                      "ADMIN",
                      "AGENCYSTAFF",
                      "AGENT",
                      "USER",
                      "AGENCY",
                      "GASSTATION",
                      "DEALER",
                      "MERCHANT",
                      "GASSTATIONSTAFF",
                      "SUDO",
                      "TECHNICALSUPPORT",
                      "COMPANYSTAFF",
                    ]}
                  >
                    <TransactionHistoriqueDetailsPage />
                  </AuthRoute>
                }
              />

              <Route
                path="transactions"
                element={
                  <AuthRoute
                    path="/transactions"
                    userRole={[
                      "ADMIN",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <TransationPage />
                  </AuthRoute>
                }
              />
              <Route
                path="affiliations"
                element={
                  <AuthRoute
                    path="/affiliations"
                    userRole={[
                      "ADMIN",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                      "USER",
                      "SUPERVISOR",
                    ]}
                  >
                    <ReferralHistoriesPage />
                  </AuthRoute>
                }
              />
              <Route
                path="affiliations/:affiliation/details"
                element={
                  <AuthRoute
                    path="/affiliations/:affiliation/details"
                    userRole={[
                      "ADMIN",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                      "SUPERVISOR",
                    ]}
                  >
                    <ReferralDetailsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="affiliations/:affiliation/seller_details"
                element={
                  <AuthRoute
                    path="/affiliations/:affiliation/seller_details"
                    userRole={[
                      "SUPERVISOR",
                    ]}
                  >
                    <SellerReferralDetailsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="withdrawWithCode"
                element={
                  <AuthRoute
                    path="/withdrawWithCode"
                    userRole={["AGENT", "AGENCY", "AGENCYSTAFF"]}
                  >
                    <WithdrawWithCode />
                  </AuthRoute>
                }
              />
              <Route
                path="dealers/new"
                element={
                  <AuthRoute
                    path="/dealers/new"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditDealer />
                  </AuthRoute>
                }
              />
              <Route
                path="dealers"
                element={
                  <AuthRoute
                    path="/dealers"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <DealersPage />
                  </AuthRoute>
                }
              />
              <Route
                path="dealers/:dealer/edit"
                element={
                  <AuthRoute
                    path="/dealers/:dealer/edit"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditDealer />
                  </AuthRoute>
                }
              />
              <Route
                path="dealers/:dealer/agents"
                element={
                  <AuthRoute
                    path="/dealers/:dealer/agents"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                      "SUPERVISOR",
                    ]}
                  >
                    <DealerAgentPage />
                  </AuthRoute>
                }
              />
              <Route
                path="dealers/make_user_to_dealer"
                element={
                  <AuthRoute
                    path="/dealers/make_user_to_dealer"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <MakeUserDealer />
                  </AuthRoute>
                }
              />
              <Route
                path="dealers/:dealer/details"
                element={
                  <AuthRoute
                    path="/dealers/:dealer/details"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <DealeryDetailsPage />
                  </AuthRoute>
                }
              />

              <Route
                path="merchants"
                element={
                  <AuthRoute
                    path="/merchants"
                    userRole={[
                      "ADMIN",
                      "MERCHANT",
                      "AGENCY",
                      "SUDO",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <MerchantsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="withdraw_merchant"
                element={
                  <AuthRoute path="/withdraw_merchant" userRole={["MERCHANT"]}>
                    <SankWithdrawMerchantPage />
                  </AuthRoute>
                }
              />
              <Route
                path="merchants/:merchant/edit"
                element={
                  <AuthRoute
                    path="/merchants/:merchant/edit"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <MerchantEditPage />
                  </AuthRoute>
                }
              />
              <Route
                path="merchants/:merchant/details"
                element={
                  <AuthRoute
                    path="/merchants/:merchant/details"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <MerchantDetailsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="merchants/new"
                element={
                  <AuthRoute
                    path="/merchants/new"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <MakeUserAMerchant />
                  </AuthRoute>
                }
              />

              <Route
                path="agents"
                element={
                  <AuthRoute
                    path="/agents"
                    userRole={[
                      "ADMIN",
                      "DEALER",
                      "AGENCY",
                      "SUDO",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AgentsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="agents/make_user_agent"
                element={
                  <AuthRoute
                    path="/agents/make_user_agent"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <MakeUserAnAgent />
                  </AuthRoute>
                }
              />
              <Route
                path="agents/new"
                element={
                  <AuthRoute
                    path="/agents/new"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditAgent />
                  </AuthRoute>
                }
              />
              <Route
                path="agents/:agent/edit"
                element={
                  <AuthRoute
                    path="/agents/:agent/edit"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditAgent />
                  </AuthRoute>
                }
              />
              <Route
                path="agents/:agent/details"
                element={
                  <AuthRoute
                    path="/agents/:agent/details"
                    userRole={[
                      "ADMIN",
                      "DEALER",
                      "AGENCY",
                      "SUDO",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AgentDetailsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="agent/users"
                element={
                  <AuthRoute path="/agent/users" userRole={["AGENT"]}>
                    <UserAffiliatedAgentPages />
                  </AuthRoute>
                }
              />
              <Route
                path="agent/users/new"
                element={
                  <AuthRoute path="/agent/users/new" userRole={["AGENT"]}>
                    <UserAffiliatedAgentEditPage />
                  </AuthRoute>
                }
              />

              <Route
                path="users"
                element={
                  <AuthRoute
                    path="/users"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <UsersPage />
                  </AuthRoute>
                }
              />
              <Route
                path="users/new"
                element={
                  <AuthRoute
                    path="/users/new"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditUserPage />
                  </AuthRoute>
                }
              />
              <Route
                path="users/:user/certify"
                element={
                  <AuthRoute
                    path="/users/:user/certify"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <CertifyUserPage />
                  </AuthRoute>
                }
              />
              <Route
                path="users/:user/edit"
                element={
                  <AuthRoute
                    path="/users/:user/edit"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditUserPage />
                  </AuthRoute>
                }
              />
              <Route
                path="users/:user/details"
                element={
                  <AuthRoute
                    path="/users/:user/details"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <UserDetailsPage />
                  </AuthRoute>
                }
              />
              {
                // Gas-Station
              }
              <Route
                path="gas-stations"
                element={
                  <AuthRoute
                    path="/gas-stations"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "GASSTATIONSTAFF",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AllGasStationListPage />
                  </AuthRoute>
                }
              />
              <Route
                path="gas-stations/:gasStation/edit"
                element={
                  <AuthRoute
                    path="/gas-stations/:gasStation/edit"
                    userRole={[
                      "ADMIN",
                      "AGENCY",
                      "SUDO",
                      "GASSTATIONSTAFF",
                      "GASSTATION",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditGasStationPage />
                  </AuthRoute>
                }
              />
              <Route
                path="gas-stations/:gasStation/details"
                element={
                  <AuthRoute
                    path="/gas-stations/:gasStation/details"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "GASSTATIONSTAFF",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <GasStationDetailsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="gas-stations/new"
                element={
                  <AuthRoute
                    path="/gas-stations/new"
                    userRole={[
                      "ADMIN",
                      "AGENCY",
                      "SUDO",
                      "GASSTATIONSTAFF",
                      "GASSTATION",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditGasStationPage />
                  </AuthRoute>
                }
              />

              <Route
                path="gas-stations/order/new"
                element={
                  <AuthRoute
                    path="/gas-stations/order/new"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "GASSTATIONSTAFF",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <OrderDetailPage />
                  </AuthRoute>
                }
              />
              <Route
                path="gas-stations/orders"
                element={
                  <AuthRoute
                    path="/gas-stations/orders"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "GASSTATIONSTAFF",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <OrdersPage />
                  </AuthRoute>
                }
              />

              <Route
                path="gas-stations/sank_staff"
                element={
                  <AuthRoute
                    path="/gas-stations/sank_staff"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "GASSTATIONSTAFF",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <GasStationAllStaffListPage />
                  </AuthRoute>
                }
              />
              <Route
                path="gas-stations/sank_staff/:sank_staff/details"
                element={
                  <AuthRoute
                    path="/gas-stations/sank_staff/:sank_staff/details"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "GASSTATIONSTAFF",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <GasStationStaffDetailsPape />
                  </AuthRoute>
                }
              />
              <Route
                path="gas-stations/sank_staff/:sank_staff/edit"
                element={
                  <AuthRoute
                    path="/gas-stations/sank_staff/:sank_staff/edit"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "GASSTATIONSTAFF",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <GasStationStaffEditPage />
                  </AuthRoute>
                }
              />
              <Route
                path="gas-stations/sank_staff/new"
                element={
                  <AuthRoute
                    path="/gas-stations/sank_staff/new"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "GASSTATIONSTAFF",
                      "AGENCYSTAFF",
                      "SUPERVISOR",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <GasStationStaffEditPage />
                  </AuthRoute>
                }
              />
              <Route
                path="withdraw_station"
                element={
                  <AuthRoute path="/withdraw_station" userRole={["GASSTATIONSTAFF"]}>
                    <SankWithdrawGasStationBalancePage />
                  </AuthRoute>
                }
              />
              {
                // Agency
              }
              <Route
                path="agencies"
                element={
                  <AuthRoute
                    path="/agencies"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AllAgenciesPage />
                  </AuthRoute>
                }
              />
              <Route
                path="agencies/:agency/details"
                element={
                  <AuthRoute
                    path="/agencies"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AgencyDetails />
                  </AuthRoute>
                }
              />
              <Route
                path="agencies/:agency/edit"
                element={
                  <AuthRoute
                    path="/agencies"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditAgencyPage />
                  </AuthRoute>
                }
              />
              <Route
                path="agencies/new"
                element={
                  <AuthRoute
                    path="/agencies"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditAgencyPage />
                  </AuthRoute>
                }
              />
              <Route
                path="agencies/audit_logs"
                element={
                  <AuthRoute
                    path="/agencies/audit_logs"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <MembersAuditLogs />
                  </AuthRoute>
                }
              />

              <Route
                path="sank_staff"
                element={
                  <AuthRoute
                    path="/agencies"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AllSankStaffPage />
                  </AuthRoute>
                }
              />
              <Route
                path="sank_staff/:sank_staff/details"
                element={
                  <AuthRoute
                    path="/agencies"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <StaffDetailsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="sank_staff/:sank_staff/edit"
                element={
                  <AuthRoute
                    path="/agencies"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AgencyStaffEditPage />
                  </AuthRoute>
                }
              />
              <Route
                path="sank_staff/new"
                element={
                  <AuthRoute
                    path="/agencies"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AgencyStaffEditPage />
                  </AuthRoute>
                }
              />
              <Route
                path="companies"
                element={
                  <AuthRoute
                    path="/companies"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <AllCompaniesPage />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/new"
                element={
                  <AuthRoute
                    path="/companies/new"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <CompanyEditPage />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/:company/edit"
                element={
                  <AuthRoute
                    path="/companies/:company/edit"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <CompanyEditPage />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/:company/details"
                element={
                  <AuthRoute
                    path="/companies/:company/details"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <CompanyDetailsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/sank_staff"
                element={
                  <AuthRoute
                    path="/companies/sank_staff"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <CompanyAllStaffListPage />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/sank_staff/new"
                element={
                  <AuthRoute
                    path="/companies/sank_staff/new"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <CompanyStaffEdit />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/sank_staff/:sank_staff/edit"
                element={
                  <AuthRoute
                    path="/companies/sank_staff/:sank_staff/edit"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <CompanyAllStaffListPage />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/sank_staff/:sank_staff/details"
                element={
                  <AuthRoute
                    path="/companies/sank_staff/:sank_staff/details"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <CompanyStaffDetailsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/contacts/contact_lists"
                element={
                  <AuthRoute
                    path="/companies/contacts/contact_lists"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <CompanyContactsList />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/contacts/add-conatct"
                element={
                  <AuthRoute
                    path="/companies/contacts/add-conatct"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <AddCompanyContact />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/contacts/:conatct/edit-conatct"
                element={
                  <AuthRoute
                    path="/companies/contacts/:conatct/edit-conatct"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <AddCompanyContact />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/bulk-paiement"
                element={
                  <AuthRoute
                    path="/companies/bulk-paiement"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <CompanyBulkPaiement />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/transactions-report"
                element={
                  <AuthRoute
                    path="/companies/transactions-report"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <ReportCompanyTransactions />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/transactions-report/items/:item"
                element={
                  <AuthRoute
                    path="/companies/transactions-report/items/:item"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <ReportTransactionsItemsPage />
                  </AuthRoute>
                }
              />
              <Route
                path="companies/bulk-payment-transactions-report"
                element={
                  <AuthRoute
                    path="/companies/bulk-payment-transactions-report"
                    userRole={["ADMIN", "SUDO", "COMPANYSTAFF", "COMPANY"]}
                  >
                    <ReportingBatchBulkPayment />
                  </AuthRoute>
                }
              />

              {/* ======================  superMerchants ======================*/}
              <Route
                path="superMerchants"
                element={
                  <AuthRoute
                    path="/superMerchants"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <SuperMerchantPage />
                  </AuthRoute>
                }
              />
              <Route
                path="superMerchants/new"
                element={
                  <AuthRoute
                    path="/superMerchants/new"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditSuperMerchant />
                  </AuthRoute>
                }
              />
              <Route
                path="superMerchants/:superMerchant/edit"
                element={
                  <AuthRoute
                    path="/superMerchants/:superMerchant/edit"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditSuperMerchant />
                  </AuthRoute>
                }
              />
              <Route
                path="superMerchants/:superMerchant/details"
                element={
                  <AuthRoute
                    path="/superMerchants/:superMerchant/details"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <SuperMerchantDetails />
                  </AuthRoute>
                }
              />
              <Route
                path="superMerchants/home"
                element={
                  <AuthRoute
                    path="/superMerchants/home"
                    userRole={["SUPERMERCHANT"]}
                  >
                    <SuperMerchantHomePage />
                  </AuthRoute>
                }
              />
              <Route
                path="superMerchants/affiliate-merchants"
                element={
                  <AuthRoute
                    path="/superMerchants/affiliate-merchants"
                    userRole={["SUPERMERCHANT"]}
                  >
                    <AffiliateMerchants />
                  </AuthRoute>
                }
              />
              <Route
                path="superMerchants/affiliate-merchants/:merchant/transactions"
                element={
                  <AuthRoute
                    path="/superMerchants/affiliate-merchants/:merchant/transactions"
                    userRole={["SUPERMERCHANT"]}
                  >
                    <MerchantTransactions />
                  </AuthRoute>
                }
              />
              <Route
                path="superMerchants/affiliate-merchants/:merchant/details"
                element={
                  <AuthRoute
                    path="/superMerchants/affiliate-merchants/:merchant/details"
                    userRole={["SUPERMERCHANT"]}
                  >
                    <MerchantDetails />
                  </AuthRoute>
                }
              />
              <Route
                path="superMerchants/transactions"
                element={
                  <AuthRoute
                    path="/superMerchants/transactions"
                    userRole={["SUPERMERCHANT"]}
                  >
                    <SuperMerchantsAllTransactions />
                  </AuthRoute>
                }
              />
              <Route
                path="superMerchants/withdraw_merchant"
                element={
                  <AuthRoute
                    path="/superMerchants/withdraw_merchant"
                    userRole={["SUPERMERCHANT"]}
                  >
                    <SankWithdrawMerchantPage />
                  </AuthRoute>
                }
              />
              {/* ======================  superStations ======================*/}
              <Route
                path="superStations"
                element={
                  <AuthRoute
                    path="/superStations"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <SuperStations />
                  </AuthRoute>
                }
              />
              <Route
                path="superStations/new"
                element={
                  <AuthRoute
                    path="/superStations/new"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditSuperStation />
                  </AuthRoute>
                }
              />
              <Route
                path="superStations/:superStation/edit"
                element={
                  <AuthRoute
                    path="/superStations/:superStation/edit"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <EditSuperStation />
                  </AuthRoute>
                }
              />

              <Route
                path="superStations/:superStation/details"
                element={
                  <AuthRoute
                    path="/superStations/:superStation/details"
                    userRole={[
                      "ADMIN",
                      "SUDO",
                      "AGENCY",
                      "AGENCYSTAFF",
                      "TECHNICALSUPPORT",
                    ]}
                  >
                    <SuperStationDetails />
                  </AuthRoute>
                }
              />
              <Route
                path="superStation/home"
                element={
                  <AuthRoute
                    path="/superStation/home"
                    userRole={["SUPERSTATION"]}
                  >
                    <SuperStationHomePage />
                  </AuthRoute>
                }
              />
              <Route
                path="superStation/affiliate-station"
                element={
                  <AuthRoute
                    path="/superStation/affiliate-station"
                    userRole={["SUPERSTATION"]}
                  >
                    <AffiliateStations />
                  </AuthRoute>
                }
              />
              <Route
                path="superStation/affiliate-stations/:station/transactions"
                element={
                  <AuthRoute
                    path="/superStation/affiliate-stations/:station/transactions"
                    userRole={["SUPERSTATION"]}
                  >
                    <StationTransactions />
                  </AuthRoute>
                }
              />
              <Route
                path="superStation/affiliate-stations/:station/details"
                element={
                  <AuthRoute
                    path="/superStation/affiliate-stations/:station/details"
                    userRole={["SUPERSTATION"]}
                  >
                    <StationDetails />
                  </AuthRoute>
                }
              />
              <Route
                path="superStation/transactions"
                element={
                  <AuthRoute
                    path="/superStation/transactions"
                    userRole={["SUPERSTATION"]}
                  >
                    <SuperStationsAllTransactions />
                  </AuthRoute>
                }
              />
              <Route
                path="superStation/withdraw_station"
                element={
                  <AuthRoute
                    path="/superStation/withdraw_station"
                    userRole={["SUPERSTATION"]}
                  >
                    <SankWithdrawGasStationBalancePage />
                  </AuthRoute>
                }
              />
              {/* ====================== */}

              <Route
                path="invoices"
                element={
                  <AuthRoute
                    path="/invoices"
                    userRole={["ADMIN", "TECHNICALSUPPORT"]}
                  >
                    <Invoices />
                  </AuthRoute>
                }
              >
                <Route
                  path="snblInvoices"
                  element={
                    <AuthRoute
                      path="/snblInvoices/prepaid"
                      userRole={["ADMIN", "TECHNICALSUPPORT"]}
                    >
                      <SNBLInvoices />
                    </AuthRoute>
                  }
                >
                  <Route
                    path="prepaid"
                    element={
                      <AuthRoute
                        path="/invoices/snblInvoices/prepaid"
                        userRole={["ADMIN", "TECHNICALSUPPORT"]}
                      >
                        <PrePaidInvoicesManageInvoices />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="postpaid"
                    element={
                      <AuthRoute
                        path="/invoices/snblInvoices/postpaid"
                        userRole={["ADMIN", "TECHNICALSUPPORT"]}
                      >
                        <PostPaidInvoicesManageInvoices />
                      </AuthRoute>
                    }
                  />
                </Route>
                <Route
                  path="onea/invoices"
                  element={
                    <AuthRoute
                      path="/onea/invoices"
                      userRole={["ADMIN", "TECHNICALSUPPORT"]}
                    >
                      <ManageOneaInvoicesPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="aggrivoucher"
                  element={
                    <AuthRoute
                      path="/aggrivoucher/payment"
                      userRole={["ADMIN", "TECHNICALSUPPORT"]}
                    >
                      <ManageAgriInvoicesPage />
                    </AuthRoute>
                  }
                >
                  <Route
                    path="payment"
                    element={
                      <AuthRoute
                        path="/invoices/aggrivoucher/payment"
                        userRole={["ADMIN", "TECHNICALSUPPORT"]}
                      >
                        <AggrivoucherPayment />
                      </AuthRoute>
                    }
                  />
                  {/* <Route
                    path="removal"
                    element={
                      <AuthRoute
                        path="/invoices/aggrivoucher/removal"
                        userRole={["ADMIN", "TECHNICALSUPPORT"]}
                      >
                        <PostPaidInvoicesManageInvoices />
                      </AuthRoute>
                    }
                  /> */}
                </Route>
              </Route>

              <Route path="" element={<ErrorPage />} />
              <Route path="*" element={<ErrorPage />} />
              <Route
                path=""
                element={
                  <Navigate
                    to={`${
                      user && user.userRole === UserRole.SUPERMERCHANT
                        ? "superMerchants/home"
                        : user && user.userRole === UserRole.SUPERSTATION
                        ? "/superStation/home"
                        : "dashboard"
                    }`}
                  />
                }
              />
            </Route>
            <Route
              path="*"
              element={
                <Navigate
                  to={`${
                    user && user.userRole === UserRole.SUPERMERCHANT
                      ? "superMerchants/home"
                      : user && user.userRole === UserRole.SUPERSTATION
                      ? "/superStation/home"
                      : "dashboard"
                  }`}
                />
              }
            />
          </Routes>
        </UserContext.Provider>
        <DestructionConfirmModal />
      </DestructiveConfirmContext.Provider>
      <CustomToaster />
    </>
  );
}

export default App;
