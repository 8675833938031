import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import {
  amountFormater,
  classNames,
  getTimestamp,
  parseDate,
} from "../../../utils";
import moment from "moment";
import {
  companyBatchPaymentsMetricsCollectionsName,
  companyCollectionName,
} from "../../../config";
import { db } from "../../../firebase";
import { UserContext } from "../../../contexts";
import { CheckCircleIcon, FunnelIcon, XCircleIcon } from "@heroicons/react/24/outline";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import Pagination from "../../../components/Pagination/Pagination";
import { Timestamp } from "firebase-admin/firestore";

const tableHeader = [
  "Date de création",
  "Nom de la compagnie",
  "Montant total payé",
  "Nombre total de transactions payées",
  "Nombre total de transactions non payées",
  "Fichier de rapport des transactions payés",
  "Fichier des transactions non payés",
  "Fichier de paiement initial",
  "Simulation",
];

const ReportingBatchBulkPayment = () => {
  const { company } = useContext(UserContext);
  const [batchData, setBatchData] = useState<any[]>([]);
  const [page, setPage] = useState(1);

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  const handleChangeStartDateValue = (value: any) => {
    const startTime = getTimestamp(value.target.value, true);
    setStartTime(startTime);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    const endTime = getTimestamp(value.target.value, false);
    setEndTime(endTime);
  };

  const getFilteredByDate = useCallback(
    async (pageLimit?: number) => {
      await db
        .collection(companyCollectionName)
        .doc(company?.id)
        .collection(companyBatchPaymentsMetricsCollectionsName)
        .orderBy("createdAt", "desc")
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .limitToLast(pageLimit ?? 50)
        .get()
        .then((response) => {
          setBatchData(
            response.docs.map((el: any) => {
              return { id: el.id, data: el.data() };
            })
          );
          setPage(page + 1);
        });
    },
    [startTime, endTime]
  );

  useEffect(() => {
    getFilteredByDate();
  }, [getFilteredByDate]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div className="ml-2 sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les rapports de paiement en masse
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des rapports de paiement en masse.
          </p>
        </div>
        <div className="h-6" />
        <div
          className={classNames(
            batchData.length > 0 ? "self-end w-[1150px]" : "w-[1500px]",
            "flex justify-around max-md:flex-col "
          )}
        >
          <div className="px-1 bg-white border border-gray-200 rounded-lg sm:flex-wrap md:flex-wrap lg:flex dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
            <div className="mr-1">
              <label
                htmlFor="dateDebut"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date début
              </label>
              <input
                type="date"
                id="dateDebut"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeStartDateValue}
              />
            </div>
            <div className="mr-1">
              <label
                htmlFor="dateFin"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date fin
              </label>
              <input
                type="date"
                id="dateFin"
                className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeEndDtaeValue}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-8 mt-6 ml-5 sm:grid-cols-2 lg:col-span-1 lg:gap-8 scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          <div className="flex flex-col mt-8">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm lg:ml-2 ring-1 ring-black ring-opacity-5">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {batchData && batchData.length > 0 ? (
                        batchData.map((data: any, referralIdx: number) => (
                          <tr key={referralIdx}>
                            <td
                              className={classNames(
                                referralIdx !== batchData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {parseDate(data.data.createdAt as Timestamp)}
                            </td>
                            <td
                              className={classNames(
                                referralIdx !== batchData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {data.data.campaignName ?? "-"}
                            </td>
                            <td
                              className={classNames(
                                referralIdx !== batchData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {amountFormater(data.data.totalAmountPaid) ?? "-"}{" "}
                              fcfa
                            </td>
                            <td
                              className={classNames(
                                referralIdx !== batchData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {data.data.totalNumberOfPaidTransaction}
                            </td>
                            <td
                              className={classNames(
                                referralIdx !== batchData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {amountFormater(
                                data.data.totalNumberOfFailedTransaction
                              ) ?? "0"}{" "}
                            </td>
                            <td
                              className={classNames(
                                referralIdx !== batchData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              <a
                                type="button"
                                href={data.data.reportFileUrl}
                                download={`Rapport_du_fichier_Des_Transactions_Non_Paye_${new Date().toDateString()}`}
                                className="inline-flex items-center gap-1.5 rounded-lg border border-primary-500 bg-primary-500 px-5 py-2.5 text-center text-sm font-medium shadow-sm transition-all hover:border-primary-700 hover:bg-primary-700 focus:ring focus:ring-primary-200 disabled:cursor-not-allowed disabled:border-primary-300 disabled:bg-primary-300"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-4 h-4 text-green-500 "
                                >
                                  <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                                  <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                                </svg>
                                Télécharger
                              </a>
                            </td>
                            <td
                              className={classNames(
                                referralIdx !== batchData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              <a
                                type="button"
                                href={data.data.unpaidFileUrl}
                                download={`Rapport_du_fichier_Des_Transactions_Non_Paye_${new Date().toDateString()}`}
                                className="inline-flex items-center gap-1.5 rounded-lg border border-primary-500 bg-primary-500 px-5 py-2.5 text-center text-sm font-medium shadow-sm transition-all hover:border-primary-700 hover:bg-primary-700 focus:ring focus:ring-primary-200 disabled:cursor-not-allowed disabled:border-primary-300 disabled:bg-primary-300"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-4 h-4 text-green-500 "
                                >
                                  <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                                  <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                                </svg>
                                Télécharger
                              </a>
                            </td>
                            <td
                              className={classNames(
                                referralIdx !== batchData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              <a
                                type="button"
                                href={data.data.archiveFileUrl}
                                download={`Rapport_du_fichier_Des_Transactions_Non_Paye_${new Date().toDateString()}`}
                                className="inline-flex items-center gap-1.5 rounded-lg border border-primary-500 bg-primary-500 px-5 py-2.5 text-center text-sm font-medium shadow-sm transition-all hover:border-primary-700 hover:bg-primary-700 focus:ring focus:ring-primary-200 disabled:cursor-not-allowed disabled:border-primary-300 disabled:bg-primary-300"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-4 h-4 text-green-500 "
                                >
                                  <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                                  <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                                </svg>
                                Télécharger
                              </a>
                            </td>
                            <td
                              className={classNames(
                                referralIdx !== batchData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {data.data.dryRun ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                  {batchData && batchData.length > 0 && (
                    <Pagination
                      skip={batchData.length}
                      take={batchData.length}
                      total={batchData.length}
                      collectionName={companyCollectionName}
                      onPageChange={(pageIndex: number) => pageIndex * page}
                      onPageLimitChange={(pageLimit: number) =>
                        getFilteredByDate(pageLimit)
                      }
                      isChangedPage={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingBatchBulkPayment;
