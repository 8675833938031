import { useCallback, useContext, useEffect, useState } from "react";
import NoContent from "../../components/TableNoContentPage/NoContent";
import Pagination from "../../components/Pagination/Pagination";
import {
  MagnifyingGlassIcon,
  ArrowPathIcon,
  PlusIcon,
  ListBulletIcon,
  Squares2X2Icon,
  BookOpenIcon,
  CubeTransparentIcon,
} from "@heroicons/react/24/outline";
import HasRoles from "../../components/HasRoles";
import {
  accountsCollectionName,
  gasStationCollectionName,
  superStationAndStationsForeingKeysCollectionsName,
  usersCollectionName,
} from "../../config";
import { AccountType, UserRole } from "../../interfaces";
import { amountFormater, classNames } from "../../utils";
import { UserContext } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";

const AffiliateStations = () => {
  const { user } = useContext(UserContext);
  const [stations, setStations] = useState<any[]>([]);
  const navigate = useNavigate();

  const retriveStations = useCallback(async () => {
    const gasstationRef = db.collection(gasStationCollectionName);
    const membershipSnapshot = await db
      .collection(superStationAndStationsForeingKeysCollectionsName)
      .where("ownerId", "==", user?.id)
      .get();
    if (!membershipSnapshot.empty) {
      const data: any[] = [];
      for (const membership of membershipSnapshot.docs) {
        const res = await gasstationRef.doc(membership?.data()?.memberId).get();
        const stationAccount = (
          await res.ref.collection(accountsCollectionName).get()
        ).docs
          .find((doc) => doc.data().accountType === AccountType.FUEL)
          ?.data();
        res.exists &&
          data.push({
            ...res.data(),
            account: stationAccount,
          });
      }
      setStations(data);
    }
  }, [user?.id]);

  const onPageLimitChange = useCallback(
    async (pageLimit: number) => {
      db.collection(superStationAndStationsForeingKeysCollectionsName)
        .where("ownerId", "==", user?.id)
        .get()
        .then(async (response) => {
          const members = [];
          for (let i = 0; i < pageLimit; i++) {
            const data = await db
              .collection(gasStationCollectionName)
              .doc(response.docs[i]?.id)
              .get();
            const stationAccount = (
              await data.ref.collection(accountsCollectionName).get()
            ).docs
              .find((doc) => doc.data().accountType === AccountType.FUEL)
              ?.data();
            members.push({
              ...data.data(),
              account: stationAccount,
            });
          }
          setStations(members);
        });
    },
    [user?.id]
  );

  const onDisplayTransactions = (merchant: any) => {
    if (merchant) {
      navigate(
        `/superStation/affiliate-stations/${merchant?.id}/transactions`,
        {
          state: { entity: merchant },
        }
      );
    }
  };
  const onDisplayStationDetails = (merchant: any) => {
    if (merchant) {
      navigate(`/superStation/affiliate-stations/${merchant?.id}/details`, {
        state: { entity: merchant },
      });
    }
  };

  useEffect(() => {
    retriveStations();
  }, [retriveStations]);

  return (
    <div className="flex flex-col max-w-5xl 2xl:max-w-7xl">
      <div className="flex flex-col">
        <div className="py-2 border-b border-gray-200 dark:border-slate-700">
          <h3 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
            La liste des stations
          </h3>
          <p className="mt-1 text-base text-gray-500">
            {`Gérez ${
              user?.userRole === UserRole.ADMIN ? "les" : "vos"
            } stations depuis cette page. Vous pouvez sélectionner une station pour voir ses informations.`}
          </p>
        </div>
        <div className="flex items-start justify-between grid-cols-1 my-3 max-md:flex-wrap lg:grid-cols-4 gap-x-5 ">
          <div className="flex self-stretch flex-1 h-10 col-span-1 border border-b border-gray-200 rounded-lg lg:col-span-2 gap-x-4 lg:gap-x-6 bg-gray-50 dark:bg-slate-800 dark:border-slate-700">
            <form className="relative flex flex-1" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="absolute inset-y-0 w-5 h-full text-gray-400 pointer-events-none left-2"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block w-full h-full py-0 pl-10 pr-2 text-gray-900 border-0 rounded-lg placeholder:text-gray-400 bg-gray-50 dark:bg-slate-800 focus:ring-0 sm:text-base"
                type="text"
                name="search"
                // value={searchedValue}
                // disabled={isTableView}
                // onChange={(e) => handleSearchCard(e.target.value)}
              />
            </form>
          </div>

          <div className="flex items-center justify-end col-span-1 my-2 space-x-2 md:my-0 lg:my-0">
            <button className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-green-700 transition-all duration-200 bg-white border border-green-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-green-900 hover:text-green-900 dark:hover:text-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
              <div
                onClick={() => {
                  // onRefresh();
                }}
                className="flex gap-1"
              >
                <ArrowPathIcon className="h-5" /> Refresh
              </div>
            </button>
          </div>
        </div>
        {stations && stations.length > 0 ? (
          <>
            <ul className="grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              {stations.map((station, eventIdx) => {
                return (
                  <li
                    key={eventIdx}
                    className="col-span-1 bg-white border divide-y divide-gray-200 rounded-lg shadow-md dark:divide-slate-700 dark:bg-slate-800 dark:border-slate-700"
                  >
                    <div className="flex items-center justify-between w-full px-6 py-4 space-x-6">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
                            {station.displayName} (
                            {station.account
                              ? `${amountFormater(
                                  station.account.balance
                                )} Fcfa`
                              : ""}
                            )
                          </h3>
                        </div>
                        {station.gasStationCode && (
                          <p className="mt-1 text-sm text-gray-500 truncate">
                            {`Code: ${station.gasStationCode}`}
                          </p>
                        )}
                        {station.email && (
                          <p className="text-xs text-gray-500 truncate">
                            {`E-mail: ${station.email}`}
                          </p>
                        )}
                      </div>
                      <span className="inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-slate-700">
                        <svg
                          className="w-full h-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <div className="flex -mt-px divide-x divide-gray-200 dark:divide-slate-700">
                        <div className="flex flex-1 w-0">
                          <div
                            onClick={() => onDisplayStationDetails(station)}
                            className="relative inline-flex items-center justify-center flex-1 w-0 py-2 -mr-px text-sm font-semibold text-gray-900 border border-transparent rounded-bl-lg cursor-pointer gap-x-2 dark:text-gray-300"
                          >
                            <button
                              disabled={!station?.active}
                              className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300"
                            >
                              <BookOpenIcon
                                className={classNames(
                                  !station?.active
                                    ? "text-gray-400"
                                    : "text-red-400",
                                  "w-5 h-5 "
                                )}
                                aria-hidden="true"
                              />
                              Details
                            </button>
                          </div>
                        </div>
                        <HasRoles userRole={["SUPERSTATION"]}>
                          <div
                            onClick={() => onDisplayTransactions(station)}
                            className="flex flex-1 w-0 -ml-px cursor-pointer"
                          >
                            <div className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300">
                              <button
                                disabled={!station?.active}
                                className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300"
                              >
                                <CubeTransparentIcon
                                  className={classNames(
                                    !station?.active
                                      ? "text-gray-400"
                                      : "text-red-400",
                                    "w-5 h-5 "
                                  )}
                                  aria-hidden="true"
                                />
                                Historique
                              </button>
                            </div>
                          </div>
                        </HasRoles>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <Pagination
              skip={stations.length}
              take={stations.length}
              total={stations.length}
              collectionName={usersCollectionName}
              onPageChange={(pageIndex: number) => pageIndex}
              onPageLimitChange={(pageLimit: number) => {
                onPageLimitChange(pageLimit);
              }}
            />
          </>
        ) : (
          <NoContent />
        )}
      </div>
    </div>
  );
};

export default AffiliateStations;
