/* eslint-disable no-lone-blocks */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  usersCollectionName,
  accountsCollectionName,
  gasStationCollectionName,
  agencyCollectionsName,
  membershipCollectionName,
  superMerchantAndMerchantsForeingKeysCollectionsName,
  transactionCollectionName,
  companyCollectionName,
} from "../../config";
import { db, functions } from "../../firebase";
import GenerateQrcode from "../Qrcode/GenerateQrcode";
import {
  AgencyStaffRole,
  UserInterface,
  UserRole,
  userRoleMap,
} from "../../interfaces";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import { classNames, getTimestamp, parseDate } from "../../utils";
import NoContent from "../TableNoContentPage/NoContent";
import HasRoles from "../HasRoles";
import { useConfirm } from "../../hooks/useConfirm";
import toast from "react-hot-toast";
import FirestoreTable, {
  IColumn,
  IRow,
  RowRenderFunction,
} from "../FirestoreTable";
import Moment from "react-moment";
import moment from "moment";
import TableRowEditMenu from "../TableRowEditMenu/TableRowEditMenu";
import { UserContext } from "../../contexts";

const tableHeader = [
  "N°",
  "Nom/prénom",
  "Email",
  "Ville",
  "Téléphone",
  "Role",
  "Active",
  "Date de création",
  "Actions",
];
const merchantsTableHeader = [
  "N°",
  "",
  "Nom/prénom",
  "Email",
  "Ville",
  "Téléphone",
  "Role",
  "Active",
  "Date de création",
  "Actions",
];

const columns: IColumn[] = [
  {
    field: "isComplete",
    label: "Statut",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "senderDisplayName",
    label: "Nom de l'expéditeur",
  },
  {
    field: "senderPhoneNumber",
    label: "Expéditeur",
  },
  {
    field: "receiverPhoneNumber",
    label: "Destinataire",
  },
  {
    field: "transactionType",
    label: "Type d'opération",
  },
  {
    field: "createdAt",
    label: "Date de création",
  },
];
const transactionTypeMap = new Map([
  ["WITHDRAW", "Retrait"],
  ["RELOAD", "Dépôt"],
]);

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "senderPhoneNumber":
      return <span>{value}</span>;
    case "receiverPhoneNumber":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "transactionType":
      return <span>{transactionTypeMap.get(value)} </span>;
    case "isComplete":
      return value ? (
        <CheckCircleIcon className="w-6 h-6 text-green-500" />
      ) : (
        <XCircleIcon className="w-6 h-6 text-red-500" />
      );
    default:
      return value as string;
  }
};
export interface DisplayItemDetailsProps {
  currentUser: UserInterface;
  members?: UserInterface[];
}

const DisplayItemDetails = ({
  currentUser,
  members,
}: DisplayItemDetailsProps) => {
  const navigate = useNavigate();
  const [userAccounts, setUserAccounts] = useState<any[]>([]);
  const [dealer, setDealer] = useState<any>();
  const [agency, setAgency] = useState<any>();
  const [showQrcodeGenerateModal, setShowQrcodeGenerateModal] = useState(false);
  const { isConfirmModal } = useConfirm();
  const [merchants, setMerchants] = useState<any[]>([]);
  const [agentCount, setAgentCount] = useState(0);
  const notResetPinCodeRole = [
    UserRole.SUPERMERCHANT,
    UserRole.SUPERVISOR,
    UserRole.AGENCY,
  ];

  const { user } = useContext(UserContext);

  const sankmoneyAdminUpdateUserPinCodeFn = functions.httpsCallable(
    "sankmoneyAdminUpdateUserPinCodeFn"
  );

  const getTransferPageLink = () => {
    switch (currentUser?.userRole) {
      case UserRole.DEALER:
        return "/transfer/reload";
      case UserRole.AGENT:
        return "/transfer/reload";
      case UserRole.USER:
        return "/transfer/reload_user";
      case UserRole.AGENCY:
        return "/transfer/reload_agency";
      default:
        return "/transfer/transfer_to_user";
    }
  };

  const getCollectionName = () => {
    switch (currentUser?.userRole) {
      case UserRole.GASSTATION:
        return gasStationCollectionName;
      case UserRole.AGENCY:
        return agencyCollectionsName;
      case UserRole.COMPANY:
        return companyCollectionName;
      default:
        return usersCollectionName;
    }
  };
  const collectionName = getCollectionName();
  const userRef = db.collection(collectionName);

  const retriveDealerInfos = async () => {
    if (currentUser?.userRole === UserRole.AGENT && currentUser?.dealerId) {
      await userRef
        .doc(currentUser?.dealerId)
        .get()
        .then((response) => {
          setDealer({ id: response.id, data: response.data() });
        });
    }
  };

  const retriveAgencyInfos = async () => {
    if (currentUser?.userRole === UserRole.AGENCYSTAFF) {
      const membershipSnapshot = await db
        .collection(membershipCollectionName)
        .where("memberId", "==", currentUser?.id)
        .get();
      const membershipData = membershipSnapshot.docs[0].data();
      await db
        .collection(agencyCollectionsName)
        .doc(membershipData?.agencyId)
        .get()
        .then((response) => {
          setAgency({
            id: response.id,
            data: response.data(),
          });
        });
    }
  };
  const adminResetPasswordFn = functions.httpsCallable("adminResetPasswordFn");
  const onResetPassword = async (id: number, entity: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir réinitialiser le code pin de l'utilisateur.",
      "Oui réinitialiser",
      true
    );
    if (entity && isConfirm) {
      const response = adminResetPasswordFn({
        email: entity.email,
        phoneNumber: entity.phoneNumber,
      });
      toast.promise(response, {
        error: (err) => `${err}`,
        success: "Le mot de passe a été reinitialiser avec succès.",
        loading: "chargement...",
      });
    }
  };

  const retriveMerchants = async () => {
    const membershipSnapshot = await db
      .collection(superMerchantAndMerchantsForeingKeysCollectionsName)
      .where("ownerId", "==", currentUser?.id)
      .get();
    if (!membershipSnapshot.empty) {
      const data: any[] = [];
      for (const membership of membershipSnapshot.docs) {
        const res = await userRef.doc(membership?.data()?.memberId).get();
        res.exists && data.push(res.data());
      }
      setMerchants(data);
    }
  };

  const onChangePINCode = async () => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir réinitialiser le code pin de l'utilisateur.",
      "Oui réinitialiser",
      true
    );

    if (isConfirm) {
      const response = sankmoneyAdminUpdateUserPinCodeFn({
        userId: currentUser?.id,
      }).then(() => {
        navigate(`/users`);
      });
      toast.promise(response, {
        error:
          "La réinitialisation du code pin à echoué. Veillez contacter le service client",
        success: "Le code pin a été réinitialiser avec success",
        loading: "chargement...",
      });
    }
  };

  const retrieveAgentCount = async () => {
    const membershipSnapshot = await userRef
      .where("userRole", "==", UserRole.AGENT)
      .where("dealerId", "==", currentUser?.id)
      .get();
    setAgentCount(membershipSnapshot.size);
  };

  useEffect(() => {
    if (currentUser) {
      userRef
        .doc(currentUser?.id)
        .collection(accountsCollectionName)
        .get()
        .then((response) => {
          setUserAccounts(
            response.docs.map((d) => {
              return d.data();
            })
          );
        });
      currentUser?.userRole === UserRole.AGENT &&
        currentUser?.dealerId &&
        retriveDealerInfos();
      currentUser?.userRole === UserRole.AGENCYSTAFF && retriveAgencyInfos();
      currentUser?.userRole === UserRole.SUPERMERCHANT && retriveMerchants();
      currentUser?.userRole === UserRole.DEALER && retrieveAgentCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [totalReload, setTotalReload] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalReloadOfMonth, setTotalReloadOfMonth] = useState(0);
  const [totalWithdrawOfMonth, setTotalWithdrawOfMonth] = useState(0);
  const [userAccountsN, setuserAccountsN] = useState<any[]>([]);
  const [isCumulativeMonthAndDay, setIsCumulativeMonthAndDay] =
    useState<boolean>(false);
  const [startTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const firstDay = moment().startOf("month");

  const lastDay = moment().endOf("month");

  // get the totoal amount of the agent reload
  const getUserTotalReload = async () => {
    const totalReload = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("transactionType", "==", "TRANSFER")
      .get();
    return totalReload.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  // get the total amount of the agent withdraw
  const getUserTotalWithdraw = async () => {
    const totalWithdraw = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("transactionType", "==", "WITHDRAW")
      .get();
    return totalWithdraw.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  const getUserTotalWithdrawmonth = async () => {
    const totalReload = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", firstDay.toDate())
      .where("createdAt", "<=", lastDay.toDate())
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("transactionType", "==", "WITHDRAW")
      .get();

    return totalReload.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  const getUserTotalReloadofmonth = async () => {
    const totalReload = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", firstDay.toDate())
      .where("createdAt", "<=", lastDay.toDate())
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("transactionType", "==", "TRANSFER")
      .get();

    return totalReload.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  useEffect(() => {
    if (currentUser) {
      userRef
        .doc(currentUser?.id)
        .collection(accountsCollectionName)
        .get()
        .then((response) => {
          setuserAccountsN(
            response.docs.map((d) => {
              return d.data();
            })
          );
        });

      getUserTotalReload().then((total) => setTotalReload(total));
      getUserTotalWithdraw().then((total) => setTotalWithdraw(total));
      getUserTotalReloadofmonth().then((total) => setTotalReloadOfMonth(total));
      getUserTotalWithdrawmonth().then((total) =>
        setTotalWithdrawOfMonth(total)
      );
    }
  }, []);

  return (
    <>
      <div className="p-16">
        <div className="p-8 mt-24 bg-white shadow">
          <div className="grid grid-cols-1 md:grid-cols-3">
            <div className="grid order-last grid-cols-3 mt-20 text-center md:order-first md:mt-0">
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}>
                {user?.userRole === UserRole.ADMIN ||
                user?.userRole === UserRole.TECHNICALSUPPORT ||
                user?.userAgencySubRole === AgencyStaffRole.MANAGER ? (
                  <>
                    {userAccounts &&
                      userAccounts.map((account, index) => (
                        <div key={index}>
                          <p className="text-xl font-bold text-gray-700">
                            {new Intl.NumberFormat().format(
                              account.balance ?? 0
                            )}{" "}
                            Fcfa
                          </p>
                          <p className="text-gray-400">
                            {account.accountType}{" "}
                          </p>
                        </div>
                      ))}
                  </>
                ) : (
                  <div />
                )}
              </HasRoles>
            </div>
            <div className="relative">
              <div className="absolute inset-x-0 top-0 flex items-center justify-center w-48 h-48 mx-auto -mt-24 text-indigo-500 bg-indigo-100 rounded-full shadow-2xl">
                <img
                  className="w-24 h-24 p-1 border rounded-full"
                  src={
                    currentUser?.profilImageUrl
                      ? currentUser?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          currentUser?.displayName || "S"
                        }`
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="flex justify-between mt-32 space-x-8 md:mt-0 md:justify-center">
              <HasRoles userRole={["AGENCYSTAFF", "AGENT", "DEALER"]}>
                <>
                  {" "}
                  {currentUser && currentUser?.userRole === UserRole.USER && (
                    <button
                      onClick={() => {
                        navigate(getTransferPageLink());
                      }}
                      className="items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-md shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition transform hover:-translate-y-0.5"
                    >
                      Recharger
                    </button>
                  )}
                </>
              </HasRoles>
              <button
                onClick={() => {
                  setShowQrcodeGenerateModal(true);
                }}
                className={classNames(
                  notResetPinCodeRole.includes(currentUser?.userRole)
                    ? "text-gray-700 border-gray-300 focus:ring-gray-500 hover:bg-gray-50 cursor-not-allowed"
                    : "text-red-700 border-red-300 focus:ring-red-500 hover:bg-red-50",
                  "items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition transform hover:-translate-y-0.5"
                )}
                disabled={
                  notResetPinCodeRole.includes(currentUser?.userRole)
                    ? true
                    : false
                }
              >
                Qrcode
              </button>
              <HasRoles userRole={["AGENCYSTAFF", "ADMIN"]}>
                <button
                  onClick={() => {
                    onChangePINCode();
                  }}
                  className={classNames(
                    notResetPinCodeRole.includes(currentUser?.userRole)
                      ? "text-gray-700 border-gray-300 focus:ring-gray-500 hover:bg-gray-50 cursor-not-allowed"
                      : "text-red-700 border-red-300 focus:ring-red-500 hover:bg-red-50",
                    "items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition transform hover:-translate-y-0.5"
                  )}
                  disabled={
                    notResetPinCodeRole.includes(currentUser?.userRole)
                      ? true
                      : false
                  }
                >
                  Réinitialiser le code PIN
                </button>
              </HasRoles>
            </div>
          </div>
          <div
            className={classNames(
              currentUser.userAgencySubRole ? "mt-32" : "mt-20",
              "pb-12 text-center border-b"
            )}
          >
            <h1 className="text-4xl font-medium text-gray-700">
              {currentUser?.displayName},
              <span className="font-light text-gray-500">
                {currentUser?.phoneNumber}
              </span>
            </h1>
            <p className="mt-3 font-light text-gray-600">
              {currentUser?.email}
            </p>
            {(dealer || agency) && (
              <p className="mt-8 text-gray-500">
                L'agent est affilié à{" "}
                <em>
                  <b>{(dealer || agency).data?.displayName}</b>
                </em>
              </p>
            )}
            <p className="mt-2 text-gray-500">
              Date de création du compte:{" "}
              {new Date(
                currentUser.createdAt.seconds * 1000 +
                  currentUser.createdAt.nanoseconds / 1000000
              ).toLocaleDateString()}
            </p>
            {currentUser.userRole === UserRole.USER && (
              <>
                <hr className="my-4" />
                <h3>Cummul des transactions du jour</h3>
                <p className="mt-2 text-gray-500">
                  Total des dépots :{" "}
                  {new Intl.NumberFormat().format(totalReload)} Fcfa
                </p>
                <p className="mt-2 text-gray-500">
                  Total des retraits :{" "}
                  {new Intl.NumberFormat().format(totalWithdraw)} Fcfa
                </p>
                <hr className="my-4" />
                <h3>Cummul des transactions du Mois</h3>
                <p className="mt-2 text-gray-500">
                  Total des dépots :{" "}
                  {new Intl.NumberFormat().format(totalReloadOfMonth)} Fcfa
                </p>
                <p className="mt-2 text-gray-500">
                  Total des retraits :{" "}
                  {new Intl.NumberFormat().format(totalWithdrawOfMonth)} Fcfa
                </p>
              </>
            )}
            {currentUser.userRole === UserRole.DEALER && (
              <p className="mt-2 text-gray-500">
                Nombre d'agents: {agentCount}
              </p>
            )}
          </div>

          {members && members.length > 0 && (
            <div className="flex flex-col mt-8">
              <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="shadow-sm">
                    <table
                      className="min-w-full border-separate"
                      style={{ borderSpacing: 0 }}
                    >
                      <thead className="bg-gray-50">
                        <tr>
                          {tableHeader.map((th, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                            >
                              {th}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {members && members.length > 0 ? (
                          members.map((member: any, memberIdx: number) => (
                            <tr key={memberIdx}>
                              <td
                                className={classNames(
                                  memberIdx !== members.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {(memberIdx as number) + 1}
                              </td>
                              <td
                                className={classNames(
                                  memberIdx !== members.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {member?.displayName as string}
                              </td>
                              <td
                                className={classNames(
                                  memberIdx !== members.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {member?.email as string}
                              </td>
                              <td
                                className={classNames(
                                  memberIdx !== members.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {member?.city as string}
                              </td>
                              <td
                                className={classNames(
                                  memberIdx !== members.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {member?.phoneNumber as string}
                              </td>
                              <td
                                className={classNames(
                                  memberIdx !== members.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {userRoleMap.get(
                                  member?.userAgencySubRole
                                    ? member?.userAgencySubRole
                                    : member?.companyAttendantRole
                                    ? member?.companyAttendantRole
                                    : member?.attendantRole
                                )}
                              </td>
                              <td
                                className={classNames(
                                  memberIdx !== members.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {member?.active ? (
                                  <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                ) : (
                                  <XCircleIcon className="w-6 h-6 text-red-500" />
                                )}
                              </td>
                              <td
                                className={classNames(
                                  memberIdx !== members.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {parseDate(member?.createdAt as Timestamp)}
                              </td>
                              <td
                                className={classNames(
                                  memberIdx !== members.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                <TableRowEditMenu
                                  rowResetPasswordHandler={() =>
                                    onResetPassword(member.id, member)
                                  }
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoContent />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {merchants && merchants.length > 0 && (
            <div className="flex flex-col mt-8">
              <div className="shadow-sm">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {merchantsTableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {merchants && merchants.length > 0 ? (
                      merchants.map((member: any, memberIdx: number) => (
                        <tr key={memberIdx}>
                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(memberIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            <img
                              className="w-10 h-10 p-1 border rounded-full"
                              src={
                                member?.profilImageUrl
                                  ? member?.profilImageUrl
                                  : `https://ui-avatars.com/api/?background=random&name=${
                                      member?.displayName || "S"
                                    }`
                              }
                              alt=""
                            />
                          </td>
                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {member?.displayName as string}
                          </td>
                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {member?.email as string}
                          </td>
                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {member?.city as string}
                          </td>
                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {member?.phoneNumber as string}
                          </td>

                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {userRoleMap.get(member?.userRole as string)}
                          </td>
                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {member?.active ? (
                              <CheckCircleIcon className="w-6 h-6 text-green-500" />
                            ) : (
                              <XCircleIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {parseDate(member?.createdAt as Timestamp)}
                          </td>
                          <td
                            className={classNames(
                              memberIdx !== merchants.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            <TableRowEditMenu
                              rowResetPasswordHandler={() =>
                                onResetPassword(member.id, member)
                              }
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <HasRoles userRole={["SUPERVISOR", "ADMIN"]}>
          <div className="shadow-sm">
            <FirestoreTable
              collectionRef={db.collection(transactionCollectionName)}
              collectionQueriesWhere={[
                {
                  fieldPath: "participantsIds",
                  opStr: "array-contains",
                  value: currentUser?.id,
                },
              ]}
              condensed
              columns={columns}
              rowRender={renderRow}
              paginate={{
                pageIndex: 0,
                limit: 100,
                sort: "createdAt:desc",
              }}
            />
          </div>
        </HasRoles>
      </div>

      <GenerateQrcode
        showQrcodeReadeModal={showQrcodeGenerateModal}
        setShowQrcodeReadeModal={setShowQrcodeGenerateModal}
        currentUser={currentUser}
        currentUserAccount={userAccounts}
      />
    </>
  );
};

export default DisplayItemDetails;
