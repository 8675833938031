import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { auth, db } from "../../firebase";
import { Dialog, Menu, Transition } from "@headlessui/react";
import SankLogoTransparent from "../../Sank_white.png";
import {
  Bars3BottomLeftIcon,
  XMarkIcon,
  BuildingLibraryIcon,
  UserGroupIcon,
  ClockIcon,
  HomeIcon,
  BoltIcon,
  ShoppingBagIcon,
  Cog8ToothIcon,
  CheckCircleIcon,
  CreditCardIcon,
  RocketLaunchIcon,
  BuildingOffice2Icon,
  BookmarkIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../utils";
import HasRoles from "../HasRoles";
import { UserContext } from "../../contexts";
import {
  AccountType,
  UserInterface,
  UserRole,
  userRoleMap,
} from "../../interfaces";
import { BellIcon } from "@heroicons/react/24/solid";
import { useRouter } from "../../hooks/useRouter";
import {
  usersCollectionName,
  accountsCollectionName,
  notificationsCollectionName,
  gasStationCollectionName,
  gasStationMembershipCollectionName,
  membershipCollectionName,
  sankmoneyFeesAccount,
  globalConfigCollectionsName,
  agentCreateUsersParams,
  superMerchantAndMerchantsForeingKeysCollectionsName,
  companiesMembershipCollectionName,
  companyCollectionName,
  superStationAndStationsForeingKeysCollectionsName,
} from "../../config";
import LoginWithOtpCode from "../Auth/Login/LoginWithOtpCode";
import SankMoneyIcon from "../../CFA.png";

const userNavigation = [{ name: "Votre profil", path: "/account/profile" }];

const adminNavigationMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Historiques", path: "/historiques", icon: ClockIcon },
  { name: "Distributeurs", path: "/dealers", icon: UserGroupIcon },
  { name: "Agents", path: "/agents", icon: UserGroupIcon },
  { name: "Super Marchants", path: "/superMerchants", icon: RocketLaunchIcon },
  { name: "Super Stations", path: "/superStations", icon: RocketLaunchIcon },
  { name: "Marchants", path: "/merchants", icon: UserGroupIcon },
  { name: "Agences", path: "/agencies", icon: BuildingLibraryIcon },
  { name: "Stations service", path: "/gas-stations", icon: BoltIcon },
  { name: "Cartes", path: "/cards", icon: CreditCardIcon },
  {
    name: "Factures",
    path: "/invoices/snblInvoices/prepaid",
    icon: TicketIcon,
  },
  { name: "Staff", path: "/sank_staff", icon: UserGroupIcon },
  { name: "Entreprises", path: "/companies", icon: BuildingOffice2Icon },
  { name: "Utilisateurs", path: "/users", icon: UserGroupIcon },
  {
    name: "Paramètres",
    path: "/settings/commissions/paid_commission_history",
    // path: "/settings/agent_create_user_params",
    icon: Cog8ToothIcon,
  },
];
const superAdminNavigationMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Historiques", path: "/historiques", icon: ClockIcon },
  { name: "Distributeurs", path: "/dealers", icon: UserGroupIcon },
  { name: "Agents", path: "/agents", icon: UserGroupIcon },
  { name: "Super Marchants", path: "/superMerchants", icon: RocketLaunchIcon },
  { name: "Super Stations", path: "/superStations", icon: RocketLaunchIcon },
  { name: "Marchants", path: "/merchants", icon: UserGroupIcon },
  { name: "Agences", path: "/agencies", icon: BuildingLibraryIcon },
  { name: "Stations service", path: "/gas-stations", icon: BoltIcon },
  { name: "Staff", path: "/sank_staff", icon: UserGroupIcon },
  { name: "Entreprises", path: "/companies", icon: BuildingOffice2Icon },
  { name: "Utilisateurs", path: "/users", icon: UserGroupIcon },
];
const dealerNavigationMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Historiques", path: "/historiques", icon: ClockIcon },
  { name: "Agents", path: "/agents", icon: UserGroupIcon },
];
const agentNavigationMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Historiques", path: "/historiques", icon: ClockIcon },
  { name: "Utilisateurs", path: "/agent/users", icon: UserGroupIcon },
];
const merchantsNavigationMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Historiques", path: "/historiques", icon: ClockIcon },
];
const gasStationNavigationMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Historiques", path: "/historiques", icon: ClockIcon },
  {
    name: "Gérants",
    path: "/gas-stations/sank_staff",
    icon: UserGroupIcon,
  },
  {
    name: "Mes commandes",
    path: "/gas-stations/orders",
    icon: ShoppingBagIcon,
  },
];
const supportTechnic = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Distributeurs", path: "/dealers", icon: UserGroupIcon },
  { name: "Agents", path: "/agents", icon: UserGroupIcon },
  { name: "Super Marchants", path: "/superMerchants", icon: RocketLaunchIcon },
  { name: "Super Stations", path: "/superStations", icon: RocketLaunchIcon },
  { name: "Marchants", path: "/merchants", icon: UserGroupIcon },
  { name: "Stations service", path: "/gas-stations", icon: BoltIcon },
  { name: "Utilisateurs", path: "/users", icon: UserGroupIcon },
  {
    name: "Factures",
    path: "/invoices/snblInvoices/prepaid",
    icon: TicketIcon,
  },
];
const agencyMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Historiques", path: "/historiques", icon: ClockIcon },
  { name: "Distributeurs", path: "/dealers", icon: UserGroupIcon },
  { name: "Agents", path: "/agents", icon: UserGroupIcon },
  { name: "Super Marchants", path: "/superMerchants", icon: RocketLaunchIcon },
  { name: "Super Stations", path: "/superStations", icon: RocketLaunchIcon },
  { name: "Marchants", path: "/merchants", icon: UserGroupIcon },
  { name: "Stations service", path: "/gas-stations", icon: BoltIcon },
  // { name: "Entreprises", path: "/companies", icon: BuildingOffice2Icon },
  { name: "Utilisateurs", path: "/users", icon: UserGroupIcon },
];

const userNavigationMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Historiques", path: "/historiques", icon: ClockIcon },
  { name: "Mes cartes", path: "/cards", icon: CreditCardIcon },
];
const companyNavigationMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Historiques", path: "/historiques", icon: ClockIcon },
  {
    name: "Membres",
    path: "/companies/sank_staff",
    icon: UserGroupIcon,
  },
  {
    name: "Contacts",
    path: "/companies/contacts/contact_lists",
    icon: BookmarkIcon,
  },
];

const supervisorNavigationMenu = [
  { name: "Accueil", path: "/dashboard", icon: HomeIcon },
  { name: "Agents", path: "/agents", icon: UserGroupIcon },
  { name: "Marchants", path: "/merchants", icon: UserGroupIcon },
  { name: "Stations service", path: "/gas-stations", icon: BoltIcon },
  { name: "Distributeurs", path: "/dealers", icon: UserGroupIcon },
  // { name: "Entreprises", path: "/companies", icon: BuildingOffice2Icon },
  { name: "Utilisateurs", path: "/users", icon: UserGroupIcon },
];
const superMerchantNavigationMenu = [
  { name: "Accueil", path: "/superMerchants/home", icon: HomeIcon },
  {
    name: "Historiques",
    path: "/superMerchants/transactions",
    icon: ClockIcon,
  },
  {
    name: "Les marchants",
    path: "/superMerchants/affiliate-merchants",
    icon: UserGroupIcon,
  },
];

const superStationNavigationMenu = [
  { name: "Accueil", path: "/superStation/home", icon: HomeIcon },
  {
    name: "Historiques",
    path: "/superStation/transactions",
    icon: ClockIcon,
  },
  {
    name: "Les stations",
    path: "/superStation/affiliate-station",
    icon: BoltIcon,
  },
];

export interface LayoutProps {
  dummy?: string;
}

function Root(props: LayoutProps) {
  const [isSignedIn, setIsSignedIn] = useState(auth.currentUser != null); // Local signed-in state.
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const {
    user,
    setUser,
    userAccounts,
    setUserAccounts,
    userNotification,
    setUserNotification,
    agencyAgent,
    setIsLoading,
    setGasStationInfo,
    setCompany,
    gasStationInfo,
    setAgentCreateUserParams,
    setSuperMerchantAffiliatedMerchants,
    setMerchantAffiliatedToSuperMerchant,
    setSuperStationAffiliatedStations,
    setStationtAffiliatedToSuperStationt,
  } = useContext(UserContext);
  const accountMap = new Map<string, any>([
    ["MAIN", <img className="w-auto h-5" src={SankMoneyIcon} alt="Sank" />],
    ["FUEL", <BoltIcon className="w-auto h-6 text-red-500" />],
  ]);
  const router = useRouter();

  const agent_create_users_params_ref = db
    .collection(globalConfigCollectionsName)
    .doc(agentCreateUsersParams);

  const updateUserState = async (userId: string) => {
    const userRef = db.collection(usersCollectionName).doc(userId);
    const user = await userRef.get();
    const userData = user.data();
    const notificationsRef = userRef.collection(notificationsCollectionName);
    const notifications = await notificationsRef.get();
    if (user.exists) {
      if (!userData?.active) {
        firebase.auth().signOut();
        return navigate("/login-with-otp");
      }
      switch (userData?.userRole) {
        case UserRole.AGENCYSTAFF:
          retriveAgencyData(userId);
          setUserNotification(notifications.docs.map((notif) => notif.data()));
          break;
        case UserRole.GASSTATIONSTAFF:
          retriveGasStationData(userId);
          retriveStationAccountData(userId);
          break;
        case UserRole.ADMIN:
          retriveAdminAccountData();
          break;
        case UserRole.MARCHAND:
          retriveMerchantAccountData(userRef, userId);
          break;
        case UserRole.SUPERMERCHANT:
          const superMerchantAccountsRef = userRef.collection(
            accountsCollectionName
          );
          const superMerchantAccounts = await superMerchantAccountsRef.get();
          setUserAccounts(
            superMerchantAccounts.docs.map((account) => account.data())
          );
          retrieveAffiliatedMerchand(userId);
          break;
        case UserRole.SUPERSTATION:
          const superStationAccountsRef = userRef.collection(
            accountsCollectionName
          );
          const superStationAccounts = await superStationAccountsRef.get();
          setUserAccounts(
            superStationAccounts.docs.map((account) => account.data())
          );
          retrieveAffiliatedStation(userId);
          break;
        case UserRole.COMPANYSTAFF:
          retriveCompanyData(userId);
          break;
        default:
          const accountsRef = userRef.collection(accountsCollectionName);
          const accounts = await accountsRef.get();
          setUserAccounts(accounts.docs.map((account) => account.data()));
          setUser(userData as UserInterface);
          setUserNotification(notifications.docs.map((notif) => notif.data()));
          break;
      }
    }
    const paramsSnap = await agent_create_users_params_ref.get();
    if (paramsSnap.exists) {
      setAgentCreateUserParams(paramsSnap?.data());
    }
  };

  const retriveAgencyData = async (userId: string) => {
    const membershipRef = db.collection(membershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", userId)
      .get();
    const userRef = db.collection(usersCollectionName);
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      const agencyAgentData = await userRef
        .where("userRole", "==", UserRole.AGENT)
        .where("agencyId", "==", membershipData?.agencyId)
        .get();
      if (agencyAgentData.empty) {
        setUserAccounts([]);
      }
      const userAccountSnapShot = await userRef
        .doc(agencyAgentData.docs[0]?.id)
        .collection(accountsCollectionName)
        .get();
      const userAccountsData = userAccountSnapShot.docs.map((account) =>
        account.data()
      );
      setUserAccounts(userAccountsData);
    }
  };

  const retriveGasStationData = async (userId: string) => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", userId)
      .get();
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      const gasStationRef = db
        .collection(gasStationCollectionName)
        .doc(membershipData?.gasStationId);
      const gasStationSnap = await gasStationRef.get();
      const userAccountSnapShot = await gasStationRef
        .collection(accountsCollectionName)
        .get();
      const userAccountsData = userAccountSnapShot.docs.map((account) =>
        account.data()
      );
      setUserAccounts(userAccountsData);
      const notificationsRef = gasStationRef.collection(
        notificationsCollectionName
      );
      if (gasStationSnap.exists) {
        setGasStationInfo(gasStationSnap.data());
      }
      const notifications = await notificationsRef.get();
      setUserNotification(notifications.docs.map((notif) => notif.data()));
    }
  };

  const retriveAdminAccountData = async () => {
    const sankAccountRef = db.collection(sankmoneyFeesAccount);
    const accountSnapshot = await sankAccountRef.get();
    if (!accountSnapshot.empty) {
      const data = accountSnapshot.docs.map((account) => account.data());
      setUserAccounts(data);
    }
  };

  const retriveMerchantAccountData = async (
    userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    id: string
  ) => {
    const accountsRef = userRef.collection(accountsCollectionName);
    const accounts = await accountsRef.get();
    const membershipSnapshot = await db
      .collection(superMerchantAndMerchantsForeingKeysCollectionsName)
      .where("memberId", "==", id)
      .get();
    if (!accounts.empty) {
      setUserAccounts(accounts.docs.map((account) => account.data()));
      if (!membershipSnapshot.empty) {
        setMerchantAffiliatedToSuperMerchant(true);
      }
    } else {
      setIsLoading(false);
    }
  };

  const retriveStationAccountData = async (id: string) => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);

    const membershipSnaphot = await membershipRef
      .where("memberId", "==", id)
      .get();
    const membershipData = membershipSnaphot.docs[0].data();
    if (!membershipSnaphot.empty) {
      const gasStationRef = db
        .collection(gasStationCollectionName)
        .doc(membershipData?.gasStationId);
      const accountsRef = gasStationRef.collection(accountsCollectionName);
      const accounts = await accountsRef.get();
      const membershipSnapshot = await db
        .collection(superStationAndStationsForeingKeysCollectionsName)
        .where("memberId", "==", membershipData?.gasStationId)
        .get();
      if (!accounts.empty) {
        setUserAccounts(accounts.docs.map((account) => account.data()));
        if (!membershipSnapshot.empty) {
          setStationtAffiliatedToSuperStationt(true);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const retrieveAffiliatedMerchand = async (id: string) => {
    const userRef = db.collection(usersCollectionName);
    const membershipSnapshot = await db
      .collection(superMerchantAndMerchantsForeingKeysCollectionsName)
      .where("ownerId", "==", id)
      .get();
    if (!membershipSnapshot.empty) {
      const data: any[] = [];
      for (const membership of membershipSnapshot.docs) {
        const res = await userRef.doc(membership?.data()?.memberId).get();
        let memberData: any;
        if (res.exists) {
          const accountsSnap = await userRef
            .doc(res.id)
            .collection(accountsCollectionName)
            .get();
          const accounts = accountsSnap.docs
            .map((account) => account.data())
            .find((el) => el.accountType === AccountType.MAIN);
          memberData = {
            accounts,
            ...res.data(),
          };
        }
        data.push(memberData);
      }
      setSuperMerchantAffiliatedMerchants(data);
    }
  };

  const retrieveAffiliatedStation = async (id: string) => {
    const membershipRef = db.collection(gasStationCollectionName);
    const membershipSnapshot = await db
      .collection(superMerchantAndMerchantsForeingKeysCollectionsName)
      .where("ownerId", "==", id)
      .get();
    if (!membershipSnapshot.empty) {
      const data: any[] = [];
      for (const membership of membershipSnapshot.docs) {
        const res = await membershipRef.doc(membership?.data()?.memberId).get();
        let memberData: any;
        if (res.exists) {
          const accountsSnap = await membershipRef
            .doc(res.id)
            .collection(accountsCollectionName)
            .get();
          const accounts = accountsSnap.docs
            .map((account) => account.data())
            .find((el) => el.accountType === AccountType.FUEL);
          memberData = {
            accounts,
            ...res.data(),
          };
        }
        data.push(memberData);
      }
      setSuperStationAffiliatedStations(data);
    }
  };

  const retriveCompanyData = async (id: string) => {
    const membershipRef = db.collection(companiesMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", id)
      .get();
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      const companyRef = db
        .collection(companyCollectionName)
        .doc(membershipData?.companyId);
      const companySnap = await companyRef.get();
      const userAccountSnapShot = await companyRef
        .collection(accountsCollectionName)
        .get();
      const notificationsSnap = await companyRef
        .collection(notificationsCollectionName)
        .get();
      const userAccountsData = userAccountSnapShot.docs.map((account) =>
        account.data()
      );
      const notificationData = notificationsSnap.docs.map((notif) =>
        notif.data()
      );
      setUserNotification(notificationData);
      setUserAccounts(userAccountsData);
      if (companySnap.exists) {
        setCompany(companySnap.data());
      }
    } else {
      setIsLoading(false);
    }
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((currentUser) => {
        if (currentUser?.uid) {
          updateUserState(currentUser?.uid as string);
          setIsSignedIn(!!currentUser);
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSignedIn) {
    return (
      <div className="flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <LoginWithOtpCode />
      </div>
    );
  }
  return (
    <>
      <div className="flex h-full">
        {/* Narrow sidebar */}
        <div className="hidden overflow-y-auto bg-red-600 w-44 lg:block">
          <div className="flex flex-col items-center w-full py-6">
            <div className="flex items-center flex-shrink-0">
              <span>
                <img
                  className="w-auto h-16"
                  src={SankLogoTransparent}
                  alt="Sank"
                />
              </span>
            </div>
            <div className="flex-1 w-full px-2 mt-6 space-y-2">
              {(() => {
                const role =
                  (user?.userRole as UserRole) || user?.userAgencySubRole;
                let navigationMenu = null;

                switch (role) {
                  case UserRole.SUDO:
                    navigationMenu = superAdminNavigationMenu;
                    break;
                  case UserRole.ADMIN:
                    navigationMenu = adminNavigationMenu;
                    break;
                  case UserRole.DEALER:
                    navigationMenu = dealerNavigationMenu;
                    break;
                  case UserRole.AGENT:
                    navigationMenu = agentNavigationMenu;
                    break;
                  case UserRole.USER:
                    navigationMenu = userNavigationMenu;
                    break;
                  case UserRole.MARCHAND:
                    navigationMenu = merchantsNavigationMenu;
                    break;
                  case UserRole.GASSTATIONSTAFF:
                    navigationMenu = gasStationNavigationMenu;
                    break;
                  case UserRole.AGENCYSTAFF:
                    navigationMenu = agencyMenu;
                    break;
                  case UserRole.TECHNICALSUPPORT:
                    navigationMenu = supportTechnic;
                    break;
                  case UserRole.SUPERVISOR:
                    navigationMenu = supervisorNavigationMenu;
                    break;
                  case UserRole.SUPERMERCHANT:
                    navigationMenu = superMerchantNavigationMenu;
                    break;
                  case UserRole.COMPANYSTAFF:
                    navigationMenu = companyNavigationMenu;
                    break;
                  case UserRole.SUPERSTATION:
                    navigationMenu = superStationNavigationMenu;
                    break;

                  default:
                    break;
                }

                return (
                  <>
                    {navigationMenu?.map((item, index) => (
                      <NavLink
                        key={index}
                        to={item.path}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-white text-black"
                              : "text-white hover:bg-white hover:text-black",
                            "group w-full px-4 py-2 my-4 rounded-md flex flex-row items-center justify-start text-sm font-medium bo  rder-t border-gray-200 dark:border-gray-700"
                          )
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <item.icon
                              className={classNames(
                                isActive
                                  ? "text-black"
                                  : "text-white group-hover:text-black ",
                                "h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span className="ml-2">{item.name}</span>
                          </>
                        )}
                      </NavLink>
                    ))}
                  </>
                );
              })()}
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-20"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-red-600">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 p-1 top-1 -mr-14">
                      <button
                        type="button"
                        className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XMarkIcon
                          className="w-6 h-6 text-white"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Fermer</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex items-center justify-center flex-shrink-0 px-4">
                    <span>
                      <img
                        className="w-auto h-16"
                        src={SankLogoTransparent}
                        alt="Sank"
                      />
                    </span>
                  </div>
                  <div className="flex-1 h-0 px-2 mt-5 overflow-y-auto">
                    <nav className="flex flex-col h-full space-y-1">
                      {(() => {
                        const role = user?.userRole as UserRole;
                        let navigationMenu = null;

                        switch (role) {
                          case UserRole.SUDO:
                            navigationMenu = superAdminNavigationMenu;
                            break;
                          case UserRole.ADMIN:
                            navigationMenu = adminNavigationMenu;
                            break;
                          case UserRole.DEALER:
                            navigationMenu = dealerNavigationMenu;
                            break;
                          case UserRole.AGENT:
                            navigationMenu = agentNavigationMenu;
                            break;
                          case UserRole.USER:
                            navigationMenu = userNavigationMenu;
                            break;
                          case UserRole.MARCHAND:
                            navigationMenu = merchantsNavigationMenu;
                            break;
                          case UserRole.GASSTATIONSTAFF:
                            navigationMenu = gasStationNavigationMenu;
                            break;
                          case UserRole.AGENCYSTAFF:
                            navigationMenu = agencyMenu;
                            break;
                          case UserRole.TECHNICALSUPPORT:
                            navigationMenu = supportTechnic;
                            break;
                          case UserRole.SUPERVISOR:
                            navigationMenu = supervisorNavigationMenu;
                            break;
                          case UserRole.SUPERMERCHANT:
                            navigationMenu = superMerchantNavigationMenu;
                            break;
                          case UserRole.COMPANYSTAFF:
                            navigationMenu = companyNavigationMenu;
                            break;
                          case UserRole.SUPERSTATION:
                            navigationMenu = superStationNavigationMenu;
                            break;

                          default:
                            break;
                        }

                        return (
                          <>
                            {navigationMenu?.map((item, index) => (
                              <NavLink
                                key={index}
                                onClick={() => setMobileMenuOpen(false)}
                                to={item.path}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive
                                      ? "bg-white text-black"
                                      : "text-white hover:bg-white hover:text-black",
                                    "group w-full px-4 py-2 my-1 rounded-md flex flex-row items-center justify-start text-sm font-medium bo  rder-t border-gray-200 dark:border-gray-700"
                                  )
                                }
                              >
                                {({ isActive }) => (
                                  <>
                                    <item.icon
                                      className={classNames(
                                        isActive
                                          ? "text-black"
                                          : "text-white group-hover:text-black ",
                                        "h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="ml-2">{item.name}</span>
                                  </>
                                )}
                              </NavLink>
                            ))}
                          </>
                        );
                      })()}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Content area */}
        <div className="flex flex-col flex-1 overflow-hidden">
          <header className="w-full">
            <div className="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm">
              <button
                type="button"
                className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500 lg:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
              </button>
              <div className="flex justify-end flex-1 px-4 sm:px-6">
                <div className="flex items-center my-auto text-sm font-medium max-md:hidden">
                  <HasRoles userRole={["USER"]}>
                    <dd
                      className={classNames(
                        user?.isCertify ? "text-green-500" : "text-red-500",
                        "flex items-center mt-3 text-sm font-medium capitalize sm:mr-6 sm:mt-0"
                      )}
                    >
                      <CheckCircleIcon
                        className={classNames(
                          user?.isCertify ? "text-green-400" : "text-red-400",
                          "mr-1.5 h-5 w-5 flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {user?.isCertify
                        ? "Certifié"
                        : "En attente de certification"}
                    </dd>
                  </HasRoles>
                </div>
                <HasRoles userRole={["AGENCYSTAFF", "AGENT"]}>
                  <div className="items-center hidden my-auto text-sm font-medium md:flex">
                    <div>
                      <span className="text-gray-500">
                        Votre code agent est :{" "}
                      </span>
                      <span className="p-1 text-orange-600 border border-spacing-1">
                        {agencyAgent ? agencyAgent?.agentCode : user?.agentCode}
                      </span>
                    </div>
                  </div>
                </HasRoles>
                {gasStationInfo && (
                  <HasRoles userRole={["GASSTATIONSTAFF"]}>
                    <div className="items-center hidden my-auto text-sm font-medium md:flex">
                      <div>
                        <span className="text-gray-500">
                          Votre code gas-station est :{" "}
                        </span>
                        <span className="p-1 text-orange-600 border border-spacing-1">
                          {gasStationInfo?.gasStationCode}
                        </span>
                      </div>
                    </div>
                  </HasRoles>
                )}
                <HasRoles userRole={["MERCHANT"]}>
                  <div className="items-center hidden my-auto text-sm font-medium md:flex">
                    <div>
                      <span className="text-gray-500">
                        Votre code marchand est :{" "}
                      </span>
                      <span className="p-1 text-pink-600 border border-spacing-1">
                        {user?.merchantCode}
                      </span>
                    </div>
                  </div>
                </HasRoles>

                <div className="items-center hidden my-auto text-sm font-medium md:flex">
                  <div>
                    <span className="text-gray-500">Connecté en tant que </span>
                    <span className="p-1 text-blue-600 border border-spacing-1">
                      {userRoleMap.get(
                        (user?.userRole === UserRole.AGENCYSTAFF
                          ? user?.userAgencySubRole
                          : user?.userRole === UserRole.GASSTATIONSTAFF
                          ? user?.attendantRole
                          : user?.userRole === UserRole.COMPANYSTAFF
                          ? user?.companyAttendantRole
                          : user?.userRole) as string
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex items-center ml-2 space-x-4 sm:ml-6 sm:space-x-6">
                  <div
                    className={classNames(
                      user && user?.userRole === UserRole.ADMIN
                        ? "hidden"
                        : "flex",
                      "divide-y max-md:flex-wrap"
                    )}
                  >
                    {userAccounts &&
                      userAccounts.length > 0 &&
                      userAccounts?.map((account, index) => (
                        <div
                          key={index}
                          className="flex px-3 py-1.5 max-md:p-0 max-md:py-0.5 text-base font-semibold md:text-lg mr-1 text-gray-500 bg-white md:border max-md:rounded-md max-md:border md:border-gray-200 md:rounded md:shadow-sm md:mb-1"
                        >
                          <span className="my-auto mr-1">
                            {accountMap.get(account.accountType)}
                          </span>
                          <span className="">
                            {new Intl.NumberFormat().format(
                              account.balance as number
                            )}{" "}
                            Fcfa
                          </span>
                        </div>
                      ))}
                  </div>

                  <div
                    className="flex text-sm bg-white rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => router.push("/notifications")}
                  >
                    <div className="relative inline-flex items-center text-sm font-medium text-center text-white">
                      <BellIcon className="w-8 h-8 p-1 text-green-500 border rounded-full" />
                      <span className="sr-only">Notifications</span>
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                        {
                          userNotification?.filter(
                            (notify) => notify.isReaded === false
                          ).length
                        }
                      </div>
                    </div>
                  </div>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="w-8 h-8 p-1 border rounded-full"
                          src={
                            user?.profilImageUrl
                              ? user?.profilImageUrl
                              : `https://ui-avatars.com/api/?background=random&name=${
                                  user?.displayName || "S"
                                }`
                          }
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-20 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-4 py-3">
                          <p className="text-sm">Connecté en tant que</p>
                          <p className="text-sm font-medium text-red-600 truncate">
                            {firebase.auth().currentUser?.email}
                          </p>
                        </div>
                        {userNavigation.map((item, index) => (
                          <div key={index}>
                            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to={item.path}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </NavLink>
                              )}
                            </Menu.Item>
                          </div>
                        ))}
                        {
                          <Menu.Item>
                            {({ active }) => (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <>
                                <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                                <div
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                  onClick={() => {
                                    firebase.auth().signOut();
                                    setIsLoading(false);
                                    navigate("/login-with-otp");
                                  }}
                                >
                                  Se déconnecter
                                </div>
                              </>
                            )}
                          </Menu.Item>
                        }
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="h-full p-1 overflow-y-auto bg-white shadow md:m-2 md:rounded-lg lg:m-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default Root;
