import { UserContext } from './../contexts/UserContext';
import { useContext } from 'react';
export default function useHasRoles() {
  const { user } = useContext(UserContext);
  const isAllowed = (
    userRole?: (
      | "SUDO"
      | "ADMIN"
      | "MERCHANT"
      | "DEALER"
      | "USER"
      | "GASSTATION"
      | "AGENCY"
      | "EMPLOYMENT"
      | "AGENT"
      | "GASSTATIONSTAFF"
      | "AGENCYSTAFF"
      | "SUPERVISOR"
      | "SUPERMERCHANT"
      | "SUPERSTATION"
      | "COMPANY"
      | "COMPANYSTAFF"
      | "TECHNICALSUPPORT"
    )[]
  ) => {
    const macthRole =
      user?.userRole === "SUDO"
        ? true
        : userRole
        ? userRole.filter((role) => role === user?.userRole).length > 0
        : true;
    return macthRole;
  };

  return { isAllowed };
}
