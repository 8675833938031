/* eslint-disable @typescript-eslint/no-empty-function */
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import { Form, FormInput } from "../../../components/FormComponents";
import { UserContext } from "../../../contexts";
import { auth, functions } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { usersCollectionName } from "../../../config";
import { UserRole } from "../../../interfaces";

interface PasswordFormField {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
interface PinCodeFormField {
  currentPinCode: string;
  newPinCode: string;
  confirmNewPinCode: string;
}

export const Security = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [canUpdatePassword, setCanUpdatePassword] = useState(false);
  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );
  const collectionName = usersCollectionName;
  const canUpdatePWD: any = [
    UserRole.ADMIN,
    UserRole.AGENCYSTAFF,
    UserRole.COMPANYSTAFF,
    UserRole.GASSTATIONSTAFF,
    UserRole.SUPERVISOR,
  ];

  const onSubmitPasswordChange = async (data: PasswordFormField) => {
    const firestoreUser = auth.currentUser;
    toast.promise(
      firestoreUser?.updatePassword(data.newPassword) as Promise<void>,
      {
        error: "La mise à jour du mot de passe à échouer",
        success: "Le mot de passe mis à jours avec success",
        loading: "Mise à jour...",
      }
    );
  };

  const onSubmitPinCodeChange = async (data: PinCodeFormField) => {
    if (user) {
      const dataValue = {
        pinCode: user?.pinCode,
        currentPinCode: data.currentPinCode,
        newPinCode: data.newPinCode,
      };
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: dataValue,
        documentId: user?.id,
      }).then(() => {
        navigate("/account/profile");
      });
      toast.promise(response, {
        error:
          "La mis à jours du code pin echoué. Veillez contacter le service client",
        success: "Le code pin a été mis à jours avec success",
        loading: "chargement...",
      });
    }
  };

  return (
    <div className="max-w-3xl mx-1 sm:mx-4">
      {canUpdatePWD.includes(user && (user?.userRole as string)) && (
        <div className="px-2 py-2 space-y-6 divide-y-2 sm:p-2">
          <div className="px-4 py-4 shadow sm:rounded-lg">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Changer le mot de passe
            </h3>
            <div className="max-w-xl mt-1 text-sm text-gray-500">
              <p>Changez votre mot de passe actuel.</p>
            </div>
            <Form<PasswordFormField>
              onSubmit={onSubmitPasswordChange}
              form={{
                resolver: yupResolver(
                  yup.object().shape({
                    currentPassword: yup.string().optional(),
                    newPassword: yup
                      .string()
                      .required("Le mot de passe est requis"),
                    confirmNewPassword: yup
                      .string()
                      .required()
                      .oneOf(
                        [yup.ref("newPassword")],
                        "Le nouveau mot de passe est requis"
                      ),
                  })
                ),
              }}
              submitButtonLabel={"Sauvegarder votre nouveau mot de passe"}
            >
              <FormInput
                type="password"
                name="currentPassword"
                label={"Mot de passe actuel"}
                autoComplete="current-password"
                helpLabel={
                  "Si vous n'avez pas de mot de passe actuel, veuillez laisser ce champ vide."
                }
              />
              <FormInput
                type="password"
                name="newPassword"
                label={"Nouveau mot de passe"}
                autoComplete="new-password"
              />
              <FormInput
                type="password"
                name="confirmNewPassword"
                label={"Confirmer le nouveau mot de passe"}
                autoComplete="new-password"
              />
            </Form>
          </div>
        </div>
      )}

      <div className="px-2 py-2 space-y-6 divide-y-2 sm:p-2">
        <div className="px-4 py-4 shadow sm:rounded-lg">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Changer votre code pin
          </h3>
          <div className="max-w-xl mt-1 text-sm text-gray-500">
            <p>Changez votre code pine actuel.</p>
          </div>
          <Form<PinCodeFormField>
            onSubmit={onSubmitPinCodeChange}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  currentPinCode: yup.string().optional(),
                  newPinCode: yup.string().required("Le code pin est requis"),
                  confirmNewPinCode: yup
                    .string()
                    .required()
                    .oneOf(
                      [yup.ref("newPinCode")],
                      "Le nouveau code pin est requis"
                    ),
                })
              ),
            }}
            submitButtonLabel={"Sauvegarder votre nouveau code pin"}
          >
            <FormInput
              name="currentPinCode"
              label={"Code pin actuel"}
              autoComplete="current-password"
              maxLength={4}
              helpLabel={
                "Si vous avez oublié votre code pin, veuillez contacter le service client."
              }
            />
            <FormInput
              name="newPinCode"
              label={"Nouveau code pin"}
              maxLength={4}
              autoComplete="new-PinCode"
            />
            <FormInput
              name="confirmNewPinCode"
              maxLength={4}
              label={"Confirmer le nouveau code pin"}
              autoComplete="new-PinCode"
            />
          </Form>
        </div>
      </div>
    </div>
  );
};
